// mui
import { Table, TableBody, TableRow } from '@mui/material';
// date-fns
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';

// utils
import { applySortFilter, getComparator } from '../../../../utils/tableUtils';
// components
import { TableCell, TableHead, TableMoreMenu } from '../../table';

// ---------------------------------------------------------------------------------------------

const SIZE = 'sm';

const getTableHead = (t) => [
  { id: 'quantity', label: t('table.quantity'), alignRight: false },
  { id: 'costPerProduct', label: t('table.costPerProduct'), alignRight: false },
  { id: 'createdAt', label: t('table.created'), alignRight: false },
  { id: 'actions' },
];

const FinishedGoodSubitemsTable = ({ list, isLoading, onEdit }) => {
  const { t } = useTranslation();

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const tableHead = getTableHead(t);
  const sortedItems = applySortFilter(list, getComparator(order, orderBy));

  return (
    <Table>
      <TableHead
        size={SIZE}
        isLoading={isLoading}
        order={order}
        orderBy={orderBy}
        headLabel={tableHead}
        rowCount={list.length}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {sortedItems.map((item) => {
          const { _id, quantity, costPerProduct, createdAt } = item;

          const _onEdit = () => {
            onEdit(_id);
          };

          return (
            <TableRow key={_id}>
              <TableCell size={SIZE} align="left" isLoading={isLoading}>
                {quantity}
              </TableCell>
              <TableCell size={SIZE} align="left" isLoading={isLoading}>
                {(costPerProduct || 0).toFixed(2)}
              </TableCell>
              <TableCell size={SIZE} align="left" isLoading={isLoading}>
                {t('date.shortWithTime', { date: parseISO(createdAt) })}
              </TableCell>
              <TableCell align="right">
                <TableMoreMenu onOpenDetails={_onEdit} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

FinishedGoodSubitemsTable.propTypes = {
  isLoading: PropTypes.bool,
  list: PropTypes.array,
  onEdit: PropTypes.func,
};

export default FinishedGoodSubitemsTable;
