//
import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// protected
import { ProtectedRoute } from './components/Protected';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import AccessDenied from './pages/AccessDenied';
// import Register from './pages/Register';
// import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Expenses from './pages/Expenses';
import FinishedGoodInventory from './pages/FinishedGoodInventory';
import Invoices from './pages/Invoices';
//
// import Blog from './pages/Blog';
import Login from './pages/Login';
import PackingLists from './pages/PackingLists';
import NotFound from './pages/Page404';
import PageInProgress from './pages/PageInProgress';
// useAuth
import { useAuth } from './providers/AuthProvider';

// ----------------------------------------------------------------------

export default function RouterWrapper() {
  const { onGetMe, isAuthentificated, loading } = useAuth();

  useEffect(() => {
    onGetMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    );

  return <Router isAuthentificated={isAuthentificated} />;
}

function Router({ isAuthentificated }) {
  return useRoutes([
    {
      path: '/dashboard',
      element: isAuthentificated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        // {
        //   path: 'users',
        //   element: (
        //     <ProtectedRoute path={'users'}>
        //       <Users />
        //     </ProtectedRoute>
        //   ),
        // },

        {
          path: 'finished-good-inventory',
          element: (
            <ProtectedRoute path={'finishedGoodInventory'}>
              <FinishedGoodInventory />
            </ProtectedRoute>
          ),
        },

        {
          path: 'purchase-orders',
          element: (
            <ProtectedRoute path={'purchase-orders'}>
              <PackingLists />
            </ProtectedRoute>
          ),
        },
        {
          path: 'invoices',
          element: (
            <ProtectedRoute path={'invoices'}>
              <Invoices />
            </ProtectedRoute>
          ),
        },
        {
          path: 'expenses',
          element: (
            <ProtectedRoute path={'expenses'}>
              <Expenses />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'login',
      element: isAuthentificated ? <Navigate to="/" /> : <Login />,
    },
    // {
    //   path: 'register',
    //   element: <Register />,
    // },
    {
      path: '/',
      element: isAuthentificated ? <LogoOnlyLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: 'in-progress', element: <PageInProgress /> },
        { path: 'access-denied', element: <AccessDenied /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
