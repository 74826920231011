// redux store
import { configureStore } from '@reduxjs/toolkit';

// Reducers
import AlertReducer from './reducers/AlertReducer';
import ExpensesReducer from './reducers/ExpensesReducer';
import FactoryReducer from './reducers/FactoryReducer';
import FinishedGoodInventoryReducer from './reducers/finishedGoodInventory';
import PackingListReducer from './reducers/packingList';
import SessionReducer from './reducers/SessionReducer';
import SettingsReducer from './reducers/SettingsReducer';
import UsersReducer from './reducers/users';

// Middlewares
// import persistMiddleware, { reHydrateStore } from './middlewares/persistMiddleware';

// ------------------------------------------------------

export const store = configureStore({
  reducer: {
    alert: AlertReducer,
    settings: SettingsReducer,

    session: SessionReducer,

    factory: FactoryReducer,
    users: UsersReducer,

    expenses: ExpensesReducer,

    finishedGoodInventory: FinishedGoodInventoryReducer,
    packingList: PackingListReducer,
  },
  // preloadedState: reHydrateStore(),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  // 	immutableCheck: { ignoredPaths: ['formPages'] },
  // 	serializableCheck: { ignoredPaths: ['formPages'] }
  // }).concat(persistMiddleware)
});
