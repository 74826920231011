import * as yup from 'yup';

// ---------------------------------------------------------------------

const getSchema = (t) =>
  yup.object().shape({
    distributor: yup.string().nullable().notRequired(),
    plNumber: yup
      .string()
      .typeError(t('validation.required'))
      .required(t('validation.required'))
      .test('existingNumber', t('packingListsPage.validation.plNumberAlreadyExists'), (value, { options }) => {
        if (!value) return true;

        const { usedPlNumbers } = options.context;

        return !usedPlNumbers?.includes(value);
      }),
    notes: yup
      .string()
      .notRequired()
      .min(1, t('validation.min', { value: 1 }))
      .max(500, t('validation.max', { value: 500 }))
      .nullable()
      .transform((value) => (value !== '' ? value : null)),
    status: yup.string().typeError(t('validation.required')).required(t('validation.required')),
    packingListDefenitions: yup.array().of(
      yup.object().shape({
        finishedGoodTemplate: yup.string().typeError(t('validation.required')).required(t('validation.required')),
        quantity: yup
          .number()
          .typeError(t('validation.number'))
          .min(1, t('validation.minNumber', { value: 1 }))
          .required(t('validation.required')),
      })
    ),
  });

export default getSchema;
