// material
import {
  Card,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
// date fns
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';

// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
// emuns
import { LoadingStatusEnum, PackingListStatusEnum } from '../../../constants/enums';
// utils
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { TableCell, TableHead, TableMoreMenu, TableToolbar } from '../table';
import StatusIcon from './StatusIcon';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'plNumber', label: '#', alignRight: false },
  { id: 'status', label: t('table.status'), alignRight: false },
  { id: 'distributor', label: t('label.distributor'), alignRight: false },
  { id: 'notes', label: t('table.notes'), alignRight: false, disableSort: true },
  { id: 'updatedAt', label: t('table.updated'), alignRight: false },
  { id: 'createdAt', label: t('table.created'), alignRight: false },
  { id: '' },
];

const getFilteredItem = (item, t) => ({
  plNumber: `#${item.plNumber}`,
  status: item.status,
  distributor: item.distributor?.name,
  notes: item.notes,
  updatedAt: t('date.shortWithTime', { date: parseISO(item.updatedAt) }),
  createdAt: t('date.shortWithTime', { date: parseISO(item.createdAt) }),
});

// ----------------------------------------------------------------------

export default function PackingListsTable({ list, onEdit, onOpenDetails, onDelete, onDownloadCsv, loading, sx }) {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const tableHead = getTableHead(t);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), filterName, (item) =>
    getFilteredItem(item, t)
  );

  const isItemNotFound = filteredItems.length === 0;

  const isLoading = loading === LoadingStatusEnum.Pending || loading === LoadingStatusEnum.Idle;

  return (
    <Card sx={sx}>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { _id, plNumber, status, distributor, notes, updatedAt, createdAt } = row;

                return (
                  <TableRow hover key={_id}>
                    <TableCell align="left" isLoading={isLoading} onClick={() => onOpenDetails(_id)}>
                      {plNumber}
                    </TableCell>
                    <TableCell align="left" isLoading={isLoading}>
                      {status ? (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                          <StatusIcon status={status} />
                          <span>{t(`packingListStatusEnum.${status}`)}</span>
                        </Stack>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align="left" isLoading={isLoading}>
                      {distributor?.name || ''}
                    </TableCell>
                    <TableCell>
                      {notes && (
                        <Tooltip title={notes}>
                          <IconButton>
                            <Iconify icon={'material-symbols:description-rounded'} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="left" isLoading={isLoading}>
                      {t('date.shortWithTime', { date: parseISO(updatedAt) })}
                    </TableCell>
                    <TableCell align="left" isLoading={isLoading}>
                      {t('date.shortWithTime', { date: parseISO(createdAt) })}
                    </TableCell>
                    <TableCell align="right" isLoading={isLoading}>
                      <TableMoreMenu
                        onEdit={() => onEdit(_id)}
                        onOpenDetails={() => onOpenDetails(_id)}
                        onDelete={status === PackingListStatusEnum.Pending ? () => onDelete(_id) : null}
                        onDownloadCsv={() => onDownloadCsv(_id)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={tableHead.length} />
                </TableRow>
              )}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                    <SearchNotFound isLoading={isLoading} searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

PackingListsTable.propTypes = {
  list: PropTypes.array,
  onEdit: PropTypes.func,
  onOpenDetails: PropTypes.func,
  onDelete: PropTypes.func,
  onDownloadCsv: PropTypes.func,
  loading: PropTypes.string,
  sx: PropTypes.object,
};
