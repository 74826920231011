// material
import { Card, Table, TableBody, TableContainer, TablePagination, TableRow } from '@mui/material';
// date-fns
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';

// components
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
// enums
import { LoadingStatusEnum } from '../../../../constants/enums';
// utils
import { applySortFilter, getComparator } from '../../../../utils/tableUtils';
import { TableCell, TableHead, TableToolbar } from '../../table';
import FinishedGoodInventoryTableRow from './FinishedGoodInventoryTableRow';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'collapse-button' },
  { id: 'finishedGoodTemplate.name', label: t('table.name'), alignRight: false },
  { id: 'finishedGoodTemplate.sku', label: t('table.sku'), alignRight: false },
  { id: 'averageCostPerProduct', label: t('table.averageCost'), alignRight: false },
  { id: 'totalQuantity', label: t('table.totalQuantity'), alignRight: false },
  { id: 'booked', label: t('table.booked'), alignRight: false },
  { id: 'totalCost', label: t('table.totalCost'), alignRight: false },
];

const getFilteredItem = (item, t) => ({
  name: item.finishedGoodTemplate?.name,
  sku: item.finishedGoodTemplate?.sku,

  averageCostPerProduct: item.averageCostPerProduct,
  totalQuantity: item.totalQuantity,
  booked: item.booked,
  totalCost: item.totalCost,

  ...item.subitems?.reduce((acc, subitem) => {
    const { _id, quantity, costPerProduct, createdAt } = subitem;

    return {
      ...acc,
      [`subitem_${_id}_quantity`]: (quantity || 0).toFixed(2),
      [`subitem_${_id}_costPerProduct`]: (costPerProduct || 0).toFixed(2),
      [`subitem_${_id}_createdAt`]: t('date.shortWithTime', { date: parseISO(createdAt) }),
    };
  }, {}),
});

// ----------------------------------------------------------------------

export default function FinishedGoodInventoryTable({ list, onEdit, loading, sx }) {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('rawMaterialTemplate.name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const tableHead = getTableHead(t);

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), filterName, (item) =>
    getFilteredItem(item, t)
  );

  const isItemNotFound = filteredItems.length === 0;

  const isLoading = loading === LoadingStatusEnum.Pending || loading === LoadingStatusEnum.Idle;

  return (
    <>
      <Card sx={sx}>
        <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead
                order={order}
                orderBy={orderBy}
                headLabel={tableHead}
                rowCount={list.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <FinishedGoodInventoryTableRow
                    key={row._id}
                    data={row}
                    isLoading={isLoading}
                    onEdit={onEdit}
                    columnCount={tableHead.length}
                  />
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={tableHead.length} />
                  </TableRow>
                )}
              </TableBody>

              {isItemNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} isLoading={isLoading} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}

FinishedGoodInventoryTable.propTypes = {
  loading: PropTypes.string,
  list: PropTypes.array,
  onEdit: PropTypes.func,
  sx: PropTypes.object,
};
