import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export const selectFinishedGoodInventoryList = (state) => state.finishedGoodInventory.list;

export const selectFinishedGoodInventoryLoading = (state) => state.finishedGoodInventory.loading;
export const selectFinishedGoodInventoryIsLoading = (state) =>
  state.finishedGoodInventory.loading === LoadingStatusEnum.Pending;

export const selectFinishedGoodInventorySelectedItem = (state) => state.finishedGoodInventory.selectedItem;
export const selectFinishedGoodInventorySelectedItemIsLoading = (state) =>
  state.finishedGoodInventory.selectedItemLoading === LoadingStatusEnum.Pending;

export default {
  list: selectFinishedGoodInventoryList,
  loading: selectFinishedGoodInventoryLoading,
  isLoading: selectFinishedGoodInventoryIsLoading,

  selectedItem: selectFinishedGoodInventorySelectedItem,
  selectedItemIsLoading: selectFinishedGoodInventorySelectedItemIsLoading,
};
