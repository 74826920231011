import PropTypes from 'prop-types';
import { useRef } from 'react';
// csv
import { CSVLink } from 'react-csv';
// i18n
import { useTranslation } from 'react-i18next';

// components
import TableMoreMenuItem from './TableMoreMenuItem';

// --------------------------------------------------------------------------------------------

const DownloadCsvTableMenuItem = ({ data, headers, filename, color, icon, children, ...rest }) => {
  const { t } = useTranslation();
  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const onClick = () => csvLink.current.link.click();

  return (
    <>
      <TableMoreMenuItem onClick={onClick} icon={icon} color={color}>
        {children || t('button.downloadCSV')}
      </TableMoreMenuItem>
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        className="hidden"
        ref={csvLink}
        target="_blank"
        {...rest}
      />
    </>
  );
};

DownloadCsvTableMenuItem.defaultProps = {
  icon: 'bi:filetype-csv',
};

DownloadCsvTableMenuItem.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  filename: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
};

export default DownloadCsvTableMenuItem;
