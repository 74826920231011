import PropTypes from 'prop-types';
import { Box, Skeleton, TableCell as MuiTableCell } from '@mui/material';
import { styled } from '@mui/system';
import { get, isFunction } from 'lodash';

// -------------------------------------------------------------------

const StyledTableCell = styled(MuiTableCell, {
  shouldForwardProp: (prop) => prop !== 'bold' && prop !== 'size' && prop !== 'noWrap',
})(({ theme, color, size, bold, onClick, noWrap }) => ({
  ...(isFunction(onClick) && {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  }),
  ...((size === 'sm' || size === 'xs') && {
    padding: theme.spacing(1),
  }),
  ...(color && { color: get(theme.palette, color) }),
  ...(size === 'xs'
    ? theme.typography[bold ? 'subtitle4' : 'body4']
    : size === 'sm'
    ? theme.typography[bold ? 'subtitle3' : 'body3']
    : theme.typography[bold ? 'subtitle2' : 'body2']),
  ...(noWrap && { whiteSpace: 'noWrap' }),
}));

const TableCell = ({ isLoading, children, ...rest }) => {
  if (isLoading)
    return (
      <StyledTableCell {...rest}>
        <Skeleton>
          <Box>{children}</Box>
        </Skeleton>
      </StyledTableCell>
    );

  return <StyledTableCell {...rest}>{children}</StyledTableCell>;
};

TableCell.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export const SmallTableCell = (props) => <TableCell {...props} size={'sm'} />;
export const ExtraSmallTableCell = (props) => <TableCell {...props} size={'xs'} />;

export default TableCell;
