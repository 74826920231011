// mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// lodash
import { isArray } from 'lodash';
// i18n
import { useTranslation } from 'react-i18next';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { selectAlertLoading, selectAlert, CloseAlert } from '../../redux/reducers/AlertReducer';
// components
import StyledModal from '../StyledModal';
import { AlertIcon } from './AlertIcon';
import { AlertTitle } from './AlertTitle';

// -----------------------------------------------------------------------------------------------

export function SimpleAlert() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const loading = useSelector(selectAlertLoading);
  const { status, text, msg, code, errors, translationValues, contactManager } = useSelector(selectAlert);

  const handleClose = () => dispatch(CloseAlert());

  const isOpen = loading === 'succeeded' || loading === 'pending';
  const isFail = status === 'fail' || status === 'error';

  if (!isOpen) return null;

  return (
    <StyledModal
      maxWidth={'xs'}
      open={isOpen}
      handleClose={handleClose}
      hideCloseButton
      sx={{ zIndex: '1350 !important' }}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
        {loading === 'pending' ? (
          <CircularProgress />
        ) : loading === 'succeeded' ? (
          <>
            {/* Alert Title */}
            <AlertTitle status={status} />

            {/* Alert Icon */}
            <AlertIcon isFail={isFail} sx={{ mb: 2 }} />

            {/* Alert Message */}
            {isArray(text) ? (
              text.map((item, idx) => (
                <Typography
                  key={`${item}_${idx}`}
                  color={'text.primary'}
                  align={'center'}
                  component={'div'}
                  sx={{ wordBreak: 'break-word' }}
                >
                  {item}
                </Typography>
              ))
            ) : (
              <Typography color={'text.primary'} align={'center'} component={'div'} sx={{ wordBreak: 'break-word' }}>
                {text || (code ? t(code) : msg ? t(`alert.codes.${msg}`, translationValues) : t('alert.serverError'))}
              </Typography>
            )}

            {errors &&
              isArray(errors) &&
              errors.map((error, idx) => (
                <Typography
                  variant={'body2'}
                  key={`${error}_${idx}`}
                  color={'text.secondary'}
                  align={'center'}
                  component={'div'}
                  sx={{ wordBreak: 'break-word' }}
                >
                  {error}
                </Typography>
              ))}

            {/* Alert Conact Manager/Admin */}
            {isFail && (
              <Typography
                variant={'body2'}
                id="alert-description"
                sx={{ mt: 1 }}
                color={'text.secondary'}
                align={'center'}
              >
                {contactManager ? t('alert.contactManager') : t('alert.contactAdmin')}
              </Typography>
            )}
          </>
        ) : null}
      </Box>
    </StyledModal>
  );
}
