import { useEffect, useState } from 'react';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetFinishedGoodInventory, GetFinishedGoodItemById } from '../redux/reducers/finishedGoodInventory/thunks';
import { ClearFinishedGoodSelectedItem } from '../redux/reducers/finishedGoodInventory';
import selectors from '../redux/reducers/finishedGoodInventory/selectors';
// components
import Page from '../components/Page';
import StyledModal from '../components/StyledModal';
import FinishedGoodInventoryTable from '../sections/@dashboard/finishedGoodInventory/FinishedGoodInventoryTable';
import FinishedGoodItemDetails from '../sections/@dashboard/finishedGoodInventory/FinishedGoodItemDetails';

// ----------------------------------------------------------------------

export default function FinishedGoodInventory() {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // state
  const list = useSelector(selectors.list);
  const loading = useSelector(selectors.loading);

  const selectedItem = useSelector(selectors.selectedItem);
  const selectedItemIsLoading = useSelector(selectors.selectedItemIsLoading);

  // methods
  const onGet = () => {
    dispatch(GetFinishedGoodInventory());
  };

  const onGetById = (id) => {
    dispatch(GetFinishedGoodItemById(id));
  };

  const onClearSelectedItem = () => {
    dispatch(ClearFinishedGoodSelectedItem());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onGet, []);

  // modal state
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const onOpenDetailsModal = (id) => {
    setDetailsModalOpen(true);
    onGetById(id);
  };

  const onCloseDetailsModal = () => {
    setDetailsModalOpen(false);
    onClearSelectedItem();
  };

  return (
    <>
      <Page title={t('pages.finishedGoodInventory')}>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" gutterBottom>
              {t('pages.finishedGoodInventory')}
            </Typography>
          </Stack>

          <FinishedGoodInventoryTable list={list} loading={loading} onEdit={onOpenDetailsModal} />
        </Container>
      </Page>
      <StyledModal
        maxWidth={'md'}
        title={selectedItem?.finishedGoodTemplate?.name || t('finishedGoodInventory.details.title')}
        open={detailsModalOpen}
        handleClose={onCloseDetailsModal}
        isLoading={selectedItemIsLoading}
      >
        <FinishedGoodItemDetails data={selectedItem} />
      </StyledModal>
    </>
  );
}
