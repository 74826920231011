import PropTypes from 'prop-types';
// mui
import { Card, Container, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// lodash
import _ from 'lodash';
// i18n
import { useTranslation } from 'react-i18next';
// components
import { DataCardSection, DataCardRow, DataCardTitle } from '../../../components/DataCardSection';
import ShippingBoxItemsTable from './ShippingBoxItemsTable';
import DefenitionsTable from './DefenitionsTable';
import ButtonWithConfirmation from '../../../components/ButtonWithConfirmation';
import Iconify from '../../../components/Iconify';
// enums
import { PackingListStatusEnum } from '../../../constants/enums';

//-------------------------------------------------------------------------------------------------

const PackingListDetails = ({
  data,
  groupedShippingBoxItems,
  onChangeStatus,
  statusIsLoading,
  onCreateInvoice,
  invoiceIsLoading,
}) => {
  const { t } = useTranslation();

  const { _id, status, plNumber, distributor, notes, packingListDefenitions, shippingBoxes } = data || {};

  const showMarkAsShipped = status === PackingListStatusEnum.Invoiced;
  const showMarkAsInvoiced = status === PackingListStatusEnum.Collected;

  const markAsShipped = () => {
    onChangeStatus(_id, PackingListStatusEnum.Shipped);
  };

  const markAsInvoiced = () => {
    onChangeStatus(_id, PackingListStatusEnum.Invoiced);
  };

  const createInvoice = () => {
    onCreateInvoice(_id);
  };

  const shippingBoxItemsList = _.flatMap(shippingBoxes, (box) => {
    const { sbNumber, shippingBoxItems, status, weight, dimensions } = box;
    return shippingBoxItems.map((item) => ({ ...item, sbNumber, status, weight, dimensions }));
  });

  const defenitionsList = _.map(
    packingListDefenitions,
    ({ _id, quantity, finishedGoodTemplate, createdAt, updatedAt }) => {
      const groupedShippingBoxItem = _.find(groupedShippingBoxItems, { _id: finishedGoodTemplate?._id });

      const packedQuantity = groupedShippingBoxItem?.totalQuantity || 0;
      const remaining = quantity - packedQuantity;

      return {
        _id,
        name: finishedGoodTemplate?.name,
        packedQuantity,
        quantity,
        remaining,
        createdAt,
        updatedAt,
      };
    }
  );

  if (!data) return null;

  return (
    <Container maxWidth={'lg'}>
      <Stack spacing={2}>
        {(distributor?.name || notes) && (
          <DataCardSection>
            {distributor?.name && (
              <DataCardRow
                title={t('label.distributor')}
                text={distributor?.name}
                titleIconProps={{ icon: 'mdi:truck-fast' }}
              />
            )}

            {notes && (
              <DataCardRow
                title={t('label.notes')}
                text={notes}
                titleIconProps={{ icon: 'mdi:note-multiple-outline' }}
                textProps={{ noWrap: false }}
              />
            )}
          </DataCardSection>
        )}

        {defenitionsList?.length > 0 && (
          <Stack spacing={1}>
            <DataCardTitle>{t('packingListsPage.details.sectionTitle.defenitions')}</DataCardTitle>
            <Card variant="outlined">
              <DefenitionsTable list={defenitionsList} />
            </Card>
          </Stack>
        )}

        {showMarkAsInvoiced && (
          <Stack justifyContent={'flex-end'} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <LoadingButton size="large" onClick={createInvoice} loading={invoiceIsLoading}>
              {t('packingListsPage.form.button.createInvoice')}
            </LoadingButton>
            <ButtonWithConfirmation
              loading={statusIsLoading}
              onAgree={markAsInvoiced}
              size="large"
              variant="contained"
              endIcon={<Iconify icon={'basil:invoice-outline'} />}
              confirmation={{
                text: t('packingListsPage.confirmation.markPackingListAsInvoiced.text', { value: plNumber }),
                agreeText: t('packingListsPage.form.button.markAsInvoiced'),
                disagreeText: t('button.cancel'),
              }}
            >
              {t('packingListsPage.form.button.markAsInvoiced')}
            </ButtonWithConfirmation>
          </Stack>
        )}

        {showMarkAsShipped && (
          <Stack justifyContent={'flex-end'} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <ButtonWithConfirmation
              loading={statusIsLoading}
              onAgree={markAsShipped}
              size="large"
              variant="contained"
              endIcon={<Iconify icon={'mdi:truck-check-outline'} />}
              confirmation={{
                text: t('packingListsPage.confirmation.markPackingListAsShipped.text', { value: plNumber }),
                agreeText: t('packingListsPage.form.button.markAsShipped'),
                disagreeText: t('button.cancel'),
              }}
            >
              {t('packingListsPage.form.button.markAsShipped')}
            </ButtonWithConfirmation>
          </Stack>
        )}

        <Stack spacing={1}>
          <DataCardTitle>{t('packingListsPage.details.sectionTitle.shippingBoxItems')}</DataCardTitle>
          <Card variant="outlined">
            <ShippingBoxItemsTable list={shippingBoxItemsList} />
          </Card>
        </Stack>
      </Stack>
    </Container>
  );
};

PackingListDetails.propTypes = {
  data: PropTypes.object,
  groupedShippingBoxItems: PropTypes.array,
  onChangeStatus: PropTypes.func,
  statusIsLoading: PropTypes.bool,
  onCreateInvoice: PropTypes.func,
  invoiceIsLoading: PropTypes.bool,
};

export default PackingListDetails;
