export const CreateUserReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      unbindEmployees: state.unbindEmployees.filter(({ _id }) => _id !== data.employee?._id),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const CreateUserByAdminReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      list: [...state.list, data],
      // unbindEmployees: state.unbindEmployees.filter(({ _id }) => _id !== data.employee?._id),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetUsersReducer = {
  pending: (state) => ({
    ...state,
    loading: 'pending',
  }),
  fulfilled: (state, action) => {
    const { data, withoutFactory } = action.payload;

    return {
      ...state,
      list: withoutFactory ? data?.usersWithoutFactory : data?.list,
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const UpdateUserReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    const oldUserData = state.list.find((item) => item._id === data._id);
    const oldEmployee = oldUserData?.employee;

    return {
      ...state,
      list: state.list.map((item) => {
        if (item._id === data._id) return data;

        return item;
      }),
      unbindEmployees: [...state.unbindEmployees, ...(oldEmployee ? [oldEmployee] : [])].filter(
        ({ _id }) => _id !== data.employee?._id
      ),
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetEmployeesReducer = {
  // pending: (state) => ({
  //   ...state,
  //   loading: 'pending',
  // }),
  fulfilled: (state, action) => {
    const { data } = action.payload;

    return {
      ...state,
      unbindEmployees: data?.list || [],
      loading: 'succeeded',
    };
  },
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetConveyorStepsReducer = {
  fulfilled: (state, action) => ({
    ...state,
    conveyorSteps: action.payload || [],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};

export const GetFeaturePermissionsReducer = {
  fulfilled: (state, action) => ({
    ...state,
    featurePermissions: action.payload.data || [],
    loading: 'succeeded',
  }),
  rejected: (state) => ({
    ...state,
    loading: 'failed',
  }),
};
