import PropTypes from 'prop-types';
// mui
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
// components
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const SwitchWithTooltip = ({ value, onChange, tooltipText, icon, size = 25, color, iconColor, sx }) => (
  <Tooltip title={tooltipText}>
    <FormControlLabel
      control={<Switch size={size < 25 ? 'small' : 'medium'} checked={value} onChange={onChange} color={color} />}
      labelPlacement={'start'}
      label={icon && <Iconify icon={icon} width={size} height={size} sx={{ color: iconColor, ...sx }} />}
    />
  </Tooltip>
);

SwitchWithTooltip.defaultProps = {
  size: 25,
  color: 'primary',
  iconColor: 'primary.main',
};

SwitchWithTooltip.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  tooltipText: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  sx: PropTypes.object,
};

export default SwitchWithTooltip;
