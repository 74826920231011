// material
import { Table, TableBody, TableContainer, TablePagination, TableRow } from '@mui/material';
// date fns
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';

// components
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
// utils
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { TableCell, TableHead, TableToolbar } from '../table';

// ----------------------------------------------------------------------

const getTableHead = (t) => [
  { id: 'sbNumber', label: t('label.sbNumber'), alignRight: false },
  { id: 'status', label: t('table.status'), alignRight: false },
  { id: 'finishedGoodTemplate.name', label: t('label.item'), alignRight: false },
  { id: 'quantity', label: t('label.quantity'), alignRight: false },
  { id: 'weight', label: t('table.weight'), alignRight: false },
  { id: 'dimensions', label: t('table.dimensions'), alignRight: false },
  { id: 'updatedAt', label: t('table.updated'), alignRight: false },
  { id: 'createdAt', label: t('table.created'), alignRight: false },
];

const getFilteredItem = (item, t) => ({
  sbNumber: `#${item.sbNumber}`,
  status: item.status,
  finishedGoodTemplateName: item.finishedGoodTemplate?.name,
  quantity: item.quantity,
  weight: item.weight,
  dimensions: item.dimensions,
  updatedAt: t('date.shortWithTime', { date: parseISO(item.updatedAt) }),
  createdAt: t('date.shortWithTime', { date: parseISO(item.createdAt) }),
});

// ----------------------------------------------------------------------

export default function ShippingBoxItemsTable({ list }) {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('sbNumber');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const tableHead = getTableHead(t);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), filterName, (item) =>
    getFilteredItem(item, t)
  );

  const isItemNotFound = filteredItems.length === 0;

  return (
    <>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const {
                  _id,
                  sbNumber,
                  status,
                  weight,
                  dimensions,
                  finishedGoodTemplate,
                  quantity,
                  updatedAt,
                  createdAt,
                } = row;

                return (
                  <TableRow hover key={_id}>
                    <TableCell align="left">{sbNumber}</TableCell>
                    <TableCell align="left">{status ? t(`shippingBoxStatusEnum.${status}`) : ''}</TableCell>
                    <TableCell align="left">{finishedGoodTemplate?.name || ''}</TableCell>
                    <TableCell align="left">{quantity || 0}</TableCell>
                    <TableCell align="left">{weight}</TableCell>
                    <TableCell align="left">{dimensions}</TableCell>
                    <TableCell align="left">{t('date.shortWithTime', { date: parseISO(updatedAt) })}</TableCell>
                    <TableCell align="left">{t('date.shortWithTime', { date: parseISO(createdAt) })}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={tableHead.length} />
                </TableRow>
              )}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

ShippingBoxItemsTable.propTypes = {
  list: PropTypes.array,
};
