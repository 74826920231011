import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// mui
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
};

export function RHFDatePicker({
  name,
  label,
  helperText,
  defaultValue,
  readOnly,
  size,
  fullWidth,
  required,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue || control._defaultValues[name] || ''}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          onChange={(e) => field.onChange(e)}
          onBlur={(e) => field.onBlur(e)}
          orientation="portrait"
          label={label}
          value={field.value || null}
          {...other}
          renderInput={(params) => (
            <TextField
              fullWidth={fullWidth}
              helperText={error?.message || helperText}
              inputProps={{ readOnly }}
              size={size || 'medium'}
              required={required}
              {...params}
              error={!!error}
            />
          )}
        />
      )}
    />
  );
}

RHFDatePicker.defaultProps = {
  fullWidth: true,
};

RHFDateTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export function RHFDateTimePicker({ name, label, helperText, required, defaultValue, readOnly, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue || control._defaultValues[name] || ''}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          // defaultDate={false}
          onChange={(e) => field.onChange(e)}
          onBlur={(e) => field.onBlur(e)}
          orientation="portrait"
          label={label}
          value={field.value || null}
          {...other}
          renderInput={(params) => (
            <TextField
              required={required}
              fullWidth
              error={!!error}
              helperText={error?.message || helperText}
              inputProps={{ readOnly }}
              {...params}
            />
          )}
        />
      )}
    />
  );
}

RHFTimePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export function RHFTimePicker({ name, label, helperText, required, defaultValue, readOnly, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue || control._defaultValues[name] || ''}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          // defaultDate={false}
          ampm={false}
          onChange={(e) => field.onChange(e)}
          onBlur={(e) => field.onBlur(e)}
          orientation="portrait"
          label={label}
          value={field.value || null}
          {...other}
          renderInput={(params) => (
            <TextField
              required={required}
              fullWidth
              inputProps={{ readOnly }}
              {...params}
              error={!!error}
              helperText={error?.message || helperText}
            />
          )}
        />
      )}
    />
  );
}
