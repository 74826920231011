import { isNumber, isString } from 'lodash';

// ------------------------------------------------------------------

export const getCigarLabel = (cigar) => {
  if (!cigar) {
    return 'No Cigar';
  }
  const { blendName, shape, quantity } = cigar;
  const sizeText = shape ? `${shape.length}x${shape.ringGauge}` : null;
  const shapeNameText = shape?.name?.value || null;
  const quantityText = isNumber(quantity) || isString(quantity) ? `${quantity}` : null;

  return `${blendName}${shapeNameText ? ` ${shapeNameText}` : ''}${sizeText ? ` ${sizeText}` : ''}${
    quantityText ? ` | ${quantityText}` : ''
  }`;
};

export const getShortCigarLabel = ({ shape }) => {
  if (shape) return `${shape.name?.value ? `${shape.name.value.charAt(0)}. ` : ''}${shape.length}x${shape.ringGauge}`;

  return null;
};
