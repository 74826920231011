// material
import { Card, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, Tooltip, Typography } from '@mui/material';
// csv
// date-fns
import { parseISO } from 'date-fns';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';

// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
// hooks
import useLocalStorage from '../../../hooks/useLocalStorage';
// services
import { getCsvData } from '../../../services/csv';
// utils
import { getComparator } from '../../../utils/tableUtils';
import { TableHead, TableToolbar } from '../table';

// ----------------------------------------------------------------------

// TODO: translate
const getTableHead = (t) => [
  { id: 'createdAt', label: t('table.created'), alignRight: false },
  { id: 'createdBy.fullName', label: t('table.createdBy'), alignRight: false },
  { id: 'balance.currency', label: t('expensesPage.table.currency'), alignRight: false },
  { id: 'type.value', label: t('expensesPage.table.type'), alignRight: false },
  { id: 'notes', label: t('expensesPage.table.notes'), alignRight: false },
  { id: 'debit', label: t('expensesPage.table.debit'), alignRight: false },
  { id: 'credit', label: t('expensesPage.table.credit'), alignRight: false },
];

// ----------------------------------------------------------------------

function applySortFilter(array, comparator, query, t) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (item) => {
      const fiteredItem = {
        type: item.type?.value,
        debit: item.debit,
        credit: item.credit,
        actionType: item.actionType,
        notes: item.notes,
        balance: item.balance.currency,
        createdAt: t('date.shortWithTime', { date: parseISO(item.createdAt) }),
        createdBy: item.createdBy.fullName,
      };

      let match = false;

      for (const key in fiteredItem) {
        if (`${fiteredItem[key]}`.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
          match = true;
          break;
        }
      }

      return match;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const getTotal = (list) =>
  list
    .reduce((acc, el) => {
      const accMatchIndex = acc.findIndex((accEl) => accEl.balance._id === el.balance._id);

      if (accMatchIndex !== -1)
        return acc.map((accEl, idx) => {
          if (accMatchIndex === idx) {
            const debit = Number((accEl.debit + (el.debit || 0)).toFixed(2));
            const credit = Number((accEl.credit + (el.credit || 0)).toFixed(2));

            return {
              ...accEl,
              debit,
              credit,
              profit: Number((debit - credit).toFixed(2)),
            };
          }

          return accEl;
        });

      return [
        ...acc,
        {
          debit: el.debit || 0,
          credit: Number((el.credit || 0).toFixed(2)),
          profit: Number(((el.debit || 0) - (el.credit || 0)).toFixed(2)),
          balance: el.balance,
        },
      ];
    }, [])
    .sort((a, b) => (a?.balance?.currency > b?.balance?.currency) - (a?.balance?.currency < b?.balance?.currency));

export default function ExpensesTable({ list, localStorageKey, filename, sx }) {
  const { t } = useTranslation();

  const [order, setOrder] = useLocalStorage(`${localStorageKey}_ORDER`, 'desc');

  const [orderBy, setOrderBy] = useLocalStorage(`${localStorageKey}_ORDER_BY`, 'createdAt');

  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const tableHead = getTableHead(t);

  const filteredList = applySortFilter(list, getComparator(order, orderBy), filterName, t);
  const total = getTotal(filteredList);

  const isItemNotFound = filteredList.length === 0;

  // Create object with csv data
  const csv = getCsvData({
    data: [
      ...filteredList,
      ...total?.map((el) => {
        const currency = el.balance?.currency;

        return {
          createdAt: `${t('table.total')} ${currency || ''}`,
          'balance.currency': currency,
          debit: el.debit,
          credit: el.credit,
        };
      }),
    ],
    tableHead,
    filename,
    formatters: {
      createdAt: (value) => {
        try {
          const parsedDate = parseISO(value);
          return t('date.shortWithTime', { date: parsedDate });
        } catch (err) {
          return value;
        }
      },
      debit: (value) => value.toFixed(2),
      credit: (value) => value.toFixed(2),
    },
  });

  return (
    <Card sx={sx}>
      <TableToolbar csv={csv} filterName={filterName} onFilterName={handleFilterByName}>
        <>
          <Grid item>
            <Stack alignItems={'center'} justifyContent={'center'} direction={'row'} spacing={2}>
              {total.map((item) => (
                <Typography
                  key={item.balance._id}
                  variant="subtitle2"
                  color={item.profit < 0 ? 'error.main' : 'success.main'}
                  noWrap
                >
                  {item.balance.currency} {item.profit}
                </Typography>
              ))}
            </Stack>
          </Grid>
        </>
      </TableToolbar>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredList.map((row) => {
                const { _id, debit, credit, balance, type, notes, createdAt, createdBy } = row;

                return (
                  <TableRow key={_id}>
                    <TableCell>{t('date.shortWithTime', { date: parseISO(createdAt) })}</TableCell>
                    <TableCell>{createdBy?.fullName}</TableCell>
                    <TableCell>{balance.currency}</TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" noWrap>
                        {type?.value || ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {notes && (
                        <Tooltip title={notes}>
                          <IconButton>
                            <Iconify icon={'material-symbols:description-rounded'} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography color={'success.main'} variant="subtitle2" noWrap>
                        {debit?.toFixed(2) || ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={'error.main'} variant="subtitle2" noWrap>
                        {credit?.toFixed(2) || ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={100} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}

            {!isItemNotFound && (
              <TableFooter>
                <TableRow>
                  <TableCell rowSpan={100}>
                    <Typography variant="subtitle2" noWrap>
                      {t('table.total')}
                    </Typography>
                  </TableCell>
                </TableRow>
                {total.map((item) => (
                  <TableRow key={item.balance._id}>
                    <TableCell />
                    <TableCell>{item.balance.currency}</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell>
                      <Typography color={'success.main'} variant="subtitle2" noWrap>
                        {item.debit?.toFixed(2) || ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color={'error.main'} variant="subtitle2" noWrap>
                        {item.credit?.toFixed(2) || ''}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

ExpensesTable.propTypes = {
  list: PropTypes.array,
  localStorageKey: PropTypes.string,
  filename: PropTypes.string,
  sx: PropTypes.object,
};
