const initialState = {
  usersWithoutFactory: [],
  list: [],
  unbindEmployees: [],
  featurePermissions: [],
  conveyorSteps: [],
  loading: 'idle',
};

export default initialState;
