import PropTypes from 'prop-types';
// mui
import { LoadingButton } from '@mui/lab';
// components
import ConfirmationDialog from './ConfirmationDialog';
// hooks
import useDisclosure from '../hooks/useDisclosure';

// -----------------------------------------------------------------------

const ButtonWithConfirmation = ({ onAgree, onDisagree, children, confirmation, ...rest }) => {
  const { isOpen, open, close } = useDisclosure();

  const disagree = () => {
    close();
    onDisagree?.();
  };

  return (
    <>
      <LoadingButton onClick={open} {...rest}>
        {children}
      </LoadingButton>
      <ConfirmationDialog open={isOpen} onAgree={onAgree} onDisagree={disagree} onClose={close} {...confirmation} />
    </>
  );
};

ButtonWithConfirmation.propTypes = {
  onAgree: PropTypes.func.isRequired,
  onDisagree: PropTypes.func,
  children: PropTypes.string,
  confirmation: PropTypes.object,
};

export default ButtonWithConfirmation;
