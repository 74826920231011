import { createAsyncThunk } from '@reduxjs/toolkit';

// api
import finishedGoodInventoryApi from '../../../api/finishedGoodInventoryApi';
import packingListApi from '../../../api/packingListApi';
// utils
import { download } from '../../../utils/files';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'packingList';


export const GetFinishedGoodInventory = createAsyncThunk(
  `${REDUCER_NAME}/getFinishedGoodInventory`,
  async (__, thunkAPI) => {
    const res = await finishedGoodInventoryApi.get();

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetGroupedShippingBoxItems = createAsyncThunk(
  `${REDUCER_NAME}/getGroupedShippingBoxItems`,
  async (id, thunkAPI) => {
    const res = await packingListApi.getGroupedShippingBoxItems(id);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const GetPackingLists = createAsyncThunk(`${REDUCER_NAME}/getPackingLists`, async (__, thunkAPI) => {
  const res = await packingListApi.get();

  if (res?.status === 200) return res.data;

  return thunkAPI.rejectWithValue(null);
});

export const GetPackingListById = createAsyncThunk(`${REDUCER_NAME}/getPackingListById`, async (id, thunkAPI) => {
  const res = await packingListApi.getById(id);

  if (res?.status === 200) return res.data;

  return thunkAPI.rejectWithValue(null);
});

export const CreatePackingList = createAsyncThunk(`${REDUCER_NAME}/createPackingList`, async (data, thunkAPI) => {
  const res = await packingListApi.create(data);

  if (res?.status === 200) return res.data;

  return thunkAPI.rejectWithValue(null);
});

export const UpdatePackingList = createAsyncThunk(
  `${REDUCER_NAME}/updatePackingList`,
  async ({ id, data }, thunkAPI) => {
    const res = await packingListApi.update(id, data);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const ChangePackingListStatus = createAsyncThunk(
  `${REDUCER_NAME}/changePackingListStatus`,
  async ({ id, status }, thunkAPI) => {
    const res = await packingListApi.changeStatus(id, status);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const CreatePackingListInvoice = createAsyncThunk(
  `${REDUCER_NAME}/createPackingListInvoice`,
  async (id, thunkAPI) => {
    const res = await packingListApi.createInvoice(id);

    if (res?.status === 200) return res.data;

    return thunkAPI.rejectWithValue(null);
  }
);

export const DeletePackingList = createAsyncThunk(`${REDUCER_NAME}/deletePackingList`, async (id, thunkAPI) => {
  const res = await packingListApi.delete(id);

  if (res?.status === 200) return res.data;

  return thunkAPI.rejectWithValue(null);
});

export const DownloadPackingListCsv = createAsyncThunk('payroll/downloadPackingListCsv', async (id, thunkAPI) => {
  const res = await packingListApi.getCsv(id);

  if (res.status === 200) {
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const fileName = res.headers['x-suggested-filename'];
    download(url, fileName);
  }

  return thunkAPI.rejectWithValue();
});
