export const mapPackingListDefenitionsToFormValues = ({ _id, quantity, finishedGoodTemplate }) => ({
  _id: _id || null,
  quantity: quantity || 0,
  finishedGoodTemplate: finishedGoodTemplate?._id || '',
});

export const defaultPackingListDefenitions = { quantity: 0, finishedGoodTemplate: '' };

const getDefaultValues = (extraValues) => ({
  distributor: '',
  plNumber: '',
  notes: '',
  packingListDefenitions: [defaultPackingListDefenitions],
  ...extraValues,
});

export default getDefaultValues;
