import PropTypes from 'prop-types';
import * as React from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Iconify from './Iconify';
import Scrollbar from './Scrollbar';

// ---------------------------------------------------------------

export default function StyledModal({
  open,
  handleClose,
  title,
  subtitle,
  hideCloseButton,
  maxWidth,
  fullScreen,
  isLoading,
  children,
  sx,
  ...rest
}) {
  const fixedHeader = title || subtitle || !hideCloseButton;

  return (
    <Dialog
      maxWidth={maxWidth || 'lg'}
      fullWidth
      onClose={handleClose}
      open={open}
      sx={{ ...sx }}
      fullScreen={fullScreen}
      {...rest}
    >
      {fixedHeader && (
        <>
          <Stack direction={'row'} p={2} spacing={2} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'column'}>
              {title && <Typography variant="h4">{title}</Typography>}
              {subtitle && (
                <Typography variant="subtitle" color={'text.secondary'}>
                  {subtitle}
                </Typography>
              )}
            </Stack>
            {!title && !subtitle && <Box />}
            {!hideCloseButton && (
              <Box display={'flex'} justifyContent={'end'}>
                <IconButton onClick={handleClose} color={'primary'}>
                  <Iconify icon={'carbon:close-outline'} width={30} height={30} />
                </IconButton>
              </Box>
            )}
          </Stack>
          <Divider />
        </>
      )}
      {isLoading ? (
        <Box py={5} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress disableShrink />
        </Box>
      ) : children ? (
        <Scrollbar sx={{ p: 2, maxHeight: fullScreen ? '100%' : '80vh' }}>{children}</Scrollbar>
      ) : null}
    </Dialog>
  );
}

StyledModal.defaultProps = {
  hideCloseButton: false,
};

StyledModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  maxWidth: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};
