import _ from 'lodash';

// -----------------------------------------------------------------------------------------

const getNextModelCode = (modelCodes, defaultPaddingLength = 4) => {
  const maxCode = _.max(_.map(modelCodes, (code) => Number(code)));
  const nextCode = (maxCode || 0) + 1;

  const paddingLength =
    _.max(_.map(modelCodes, (code) => (code && `${code}`.startsWith('0') ? `${code}`.length : 0))) ||
    defaultPaddingLength;

  return nextCode.toString().padStart(paddingLength, '0');
};

export default getNextModelCode;
