export const LoadingStatusEnum = Object.freeze({
  Idle: 'idle',
  Pending: 'pending',
  Succeeded: 'succeeded',
  Failed: 'failed',
});

export const MaritalStatusEnum = Object.freeze({
  Single: 'Single',
  Engaged: 'Engaged',
  Married: 'Married',
  InRelationship: 'In A Relationship',
  Cohabiting: 'Cohabiting',
  Widowed: 'Widowed',
  Divorced: 'Divorced',
  Separated: 'Separated',
});

export const EmployeeCheckInStatusEnum = Object.freeze({
  Present: 'Present',
  Absent: 'Absent',
  Sick: 'Sick',
  Vacation: 'Vacation',
  DayOff: 'Day Off',
  NotWorkDay: 'Not Work Day',
  PublicHoliday: 'Public Holiday',
});

export const AttendanceStatusEnum = Object.freeze({
  Pending: 'Pending',
  Closed: 'Closed',
  Paid: 'Paid',
});

export const PayrollStatusEnum = Object.freeze({
  Pending: 'Pending',
  ReadyToPay: 'ReadyToPay',
  Paid: 'Paid',
});

export const DaysOfTheWeek = Object.freeze({
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
});

export const SelectOptions = Object.freeze({
  Any: 'Any',
  Empty: 'Empty',
  Manual: 'Manual',
});

export const TaxTypeEnum = Object.freeze({
  Deductible: 'Deductible',
  NonDeductible: 'NonDeductible',
});

export const InventoryItemTypeEnum = Object.freeze({
  Cigar: 'Cigar',
  Box: 'Box',
  Another: 'Another',
});

export const Weekday = Object.freeze({
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
});

export const DepartmentRequestTaskStatusEnum = Object.freeze({
  Pending: 'Pending',
  InProcess: 'InProcess',
  Done: 'Done',
});

export const DepartmentRequestStatusEnum = Object.freeze({
  Pending: 'Pending',
  Done: 'Done',
});

export const PurchaseOrderTaskStatusEnum = Object.freeze({
  Pending: 'Pending',
  InProcess: 'InProcess',
  Done: 'Done',
});

export const PurchaseOrderStatusEnum = Object.freeze({
  Pending: 'Pending',
  Done: 'Done',
});

export const CigarMakingMethodEnum = Object.freeze({
  HandMade: 'Hand Made',
  MachineMade: 'Machine Made',
});

export const PackingListStatusEnum = Object.freeze({
  Pending: 'Pending',
  ReadyForCollection: 'Ready For Collection',
  Collected: 'Collected',
  Invoiced: 'Invoiced',
  Shipped: 'Shipped',
});

export const ShippingBoxStatusEnum = Object.freeze({
  Pending: 'Pending',
  Sealed: 'Sealed',
  Shipped: 'Shipped',
});

export const FactoryOptionNameEnum = Object.freeze({
  ExpensesTypes: 'expensesTypes',
  CigarUnitStatuses: 'cigarUnitStatuses',
  TobaccoContainerStatuses: 'tobaccoContainerStatuses',
  CigarTiers: 'cigarTiers',
  CigarShapes: 'cigarShapes',
  FactoryConfig: 'factoryConfig',
  TobaccoFarms: 'tobaccoFarms',
  TobaccoTypes: 'tobaccoTypes',
  TobaccoClassifications: 'tobaccoClassifications',
  TobaccoSizes: 'tobaccoSizes',
  TobaccoSeeds: 'tobaccoSeeds',
  TobaccoColors: 'tobaccoColors',
  TobaccoYears: 'tobaccoYears',
});
