import PropTypes from 'prop-types';
import { useEffect, memo } from 'react';
// material
import { Stack } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// form
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// yup
import * as yup from 'yup';
// hooks
import useFormPersist from 'react-hook-form-persist';
// auth
import { useAuth } from '../../../providers/AuthProvider';
import { FormProvider, RHFDatePicker } from '..';

// ------------------------------------------

const DateRange = ({ onSubmit, page }) => {
  const { t } = useTranslation();

  const { factory, email } = useAuth();

  // form
  const LOCAL_STORAGE_FORM_DATA_KEY = `${factory?._id}_${email.toUpperCase()}_${page}_DATE_RANGE`;

  const defaultValues = {
    startDate: new Date(),
    endDate: new Date(),
  };

  const schema = yup.object().shape({
    startDate: yup.date().typeError(t('validation.required')).required(t('validation.required')),
    endDate: yup.date().typeError(t('validation.required')).required(t('validation.required')),
  });

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit, control, watch, setValue } = methods;

  const startDate = useWatch({ name: 'startDate', control });
  const endDate = useWatch({ name: 'endDate', control });

  // form persist
  useFormPersist(LOCAL_STORAGE_FORM_DATA_KEY, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  // form submit on change
  useEffect(() => {
    watch(handleSubmit(onSubmit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  // form submit on first render
  useEffect(() => {
    handleSubmit(onSubmit)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, (error) => console.error(error))}>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} alignItems="flex-start" justifyContent={'center'}>
        <RHFDatePicker maxDate={endDate} name={'startDate'} type={'date'} label={'Start'} />
        <RHFDatePicker minDate={startDate} name={'endDate'} type={'date'} label={'End'} />
      </Stack>
    </FormProvider>
  );
};

DateRange.propTypes = {
  onSubmit: PropTypes.func,
  page: PropTypes.string.isRequired,
};

export default memo(DateRange);
