// material
import { Table, TableBody, TableContainer, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';

// components
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
// utils
import { applySortFilter, getComparator } from '../../../utils/tableUtils';
import { TableCell, TableHead } from '../table';

// ----------------------------------------------------------------------

// TODO: Translate
const getTableHead = (t) => [
  { id: 'name', label: t('label.finishedGood'), alignRight: false },
  { id: 'packedQuantity', label: t('label.packed'), alignRight: false },
  { id: 'quantity', label: t('label.quantity'), alignRight: false },
  { id: 'remaining', label: t('label.remaining'), alignRight: false },
];

const getFilteredItem = (item) => ({
  name: item.name,
  packedQuantity: item.packedQuantity,
  quantity: item.quantity,
  remaining: item.remaining,
});

// ----------------------------------------------------------------------

export default function DefenitionsTable({ list }) {
  const { t } = useTranslation();

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const tableHead = getTableHead(t);

  const filteredItems = applySortFilter(list, getComparator(order, orderBy), null, (item) => getFilteredItem(item));

  const isItemNotFound = filteredItems.length === 0;

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredItems.map((row) => {
                const { _id, name, quantity, packedQuantity, remaining } = row;

                return (
                  <TableRow hover key={_id}>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{packedQuantity}</TableCell>
                    <TableCell align="left">{quantity}</TableCell>
                    <TableCell align="left">{remaining}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            {isItemNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={tableHead.length} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={null} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </>
  );
}

DefenitionsTable.propTypes = {
  list: PropTypes.array,
};
