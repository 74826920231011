import React, { useState, useEffect, useRef } from 'react';
// MUI
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
// i18n
import { useTranslation } from 'react-i18next';
import { availableLanguages } from '../../locales/i18n';
// components
import MenuPopover from '../../components/MenuPopover';

const availableLocales = availableLanguages.map((language) => language.locale);

const LanguagePopover = () => {
  const { i18n } = useTranslation();
  const [currentLang, setLang] = useState(i18n.language || window.localStorage.i18nextLng || 'en');
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const anchorRef = useRef(null);

  // we need This useEffect Bcs language detector can set uk-UA, en-US etc in localStorage
  useEffect(() => {
    setLang((currentLang) => {
      if (availableLocales.includes(currentLang.split('-')[0])) return currentLang.split('-')[0];

      // if (availableLocales.includes(currentLang))
      return currentLang;
    });
  }, []);

  const onLanguageChange = (value) => {
    const lang = value;
    setLang(lang);
    i18n.changeLanguage(lang);
    handleClose();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img
          style={{ borderRadius: 3, width: 30 }}
          src={
            availableLocales.includes(currentLang)
              ? availableLanguages.find((lang) => lang.locale === currentLang).icon
              : availableLanguages.find((lang) => lang.locale === 'en').icon
          }
          alt={`language-${currentLang}`}
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 150,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {availableLanguages.map((option) => (
            <MenuItem
              key={option.locale}
              selected={option.locale === currentLang}
              onClick={() => onLanguageChange(option.locale)}
            >
              <Box
                component="img"
                alt={option.name.toUpperCase()}
                src={option.icon}
                sx={{ width: 28, mr: 2, borderRadius: 0.3 }}
              />

              {option.name.toUpperCase()}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
};

export default LanguagePopover;
