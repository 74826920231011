import { createSlice } from '@reduxjs/toolkit';

// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// reducers
import { ChangePackingListStatusReducer, ClearGroupedShippingBoxItemsReducer, ClearSelectedPackingListReducer, CreatePackingListInvoiceReducer, CreatePackingListReducer, DeletePackingListReducer, GetFinishedGoodInventoryReducer, GetGroupedShippingBoxItemsReducer, GetPackingListByIdReducer, GetPackingListsReducer, UpdatePackingListReducer } from './reducers';
// thunks
import { ChangePackingListStatus, CreatePackingList, CreatePackingListInvoice, DeletePackingList, GetFinishedGoodInventory, GetGroupedShippingBoxItems, GetPackingListById, GetPackingLists, UpdatePackingList } from './thunks';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'packingList',
  initialState,
  reducers: {
    ClearSelectedPackingList: ClearSelectedPackingListReducer,
    ClearGroupedShippingBoxItems: ClearGroupedShippingBoxItemsReducer,
  },
  extraReducers: (builder) => {
    // GetFinishedGoodInventory
    addExtraReducer(builder, GetFinishedGoodInventory, GetFinishedGoodInventoryReducer);
    // GetGroupedShippingBoxItems
    addExtraReducer(builder, GetGroupedShippingBoxItems, GetGroupedShippingBoxItemsReducer);
    // GetPackingLists
    addExtraReducer(builder, GetPackingLists, GetPackingListsReducer);
    // GetPackingListById
    addExtraReducer(builder, GetPackingListById, GetPackingListByIdReducer);
    // CreatePackingList
    addExtraReducer(builder, CreatePackingList, CreatePackingListReducer);
    // UpdatePackingList
    addExtraReducer(builder, UpdatePackingList, UpdatePackingListReducer);
    // ChangePackingListStatus
    addExtraReducer(builder, ChangePackingListStatus, ChangePackingListStatusReducer);
    // CreatePackingListInvoice
    addExtraReducer(builder, CreatePackingListInvoice, CreatePackingListInvoiceReducer);
    // DeletePackingList
    addExtraReducer(builder, DeletePackingList, DeletePackingListReducer);
  },
});

export default slice.reducer;
