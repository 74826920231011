export const UserRoleEnum = Object.freeze({
  Admin: 'Admin',
  Owner: 'Owner', // factory admin
  Manager: 'Manager',
  BrandOwner: 'Brand Owner',
  Supervisor: 'Supervisor',
  Worker: 'Worker',
  Distributor: 'Distributor',
});

export const routesAccess = {
  factories: [UserRoleEnum.Admin],
  users: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  finishedGoodInventory: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Distributor],
  packingLists: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Distributor],
  invoices: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
  expenses: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager],
};

// Feature Access is better name
export const componentsAccess = {
  factories: {
    create: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
  },
  users: {
    create: [UserRoleEnum.Admin, UserRoleEnum.Owner, UserRoleEnum.Manager, UserRoleEnum.Supervisor],
    createAdmin: [UserRoleEnum.Admin],
    createOwner: [UserRoleEnum.Admin],
    createBrandOwner: [UserRoleEnum.Admin],
  },
};
