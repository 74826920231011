// import logo from '../assets/img/appLogo.png';

// --------------------------------------------------

const config = {
  apiAdress: process.env.REACT_APP_API_ADRESS,

  // appearance
  // logo,
  // layout: 'sidebar', // tabs | sidebar

  // Contacts
  // phoneNumber: "844-818-6243",
  // email: "AFPsupport@mcgriff.com",
};

export default config;
