import PropTypes from 'prop-types';
// form
import { useWatch } from 'react-hook-form';
// @mui
import { IconButton, Grid, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// lodash
import _ from 'lodash';
// components
import { RHFAutocomplete, RHFTextField } from '../../../../../components/hook-form';
import Iconify from '../../../../../components/Iconify';

//-------------------------------------------------------------------------------------------------

const FinishedGoodsFields = ({
  // data,
  finishedGoodInventory,
  defaultValue,
  onRemove,
  selectedFinishedGoods,
  index,
  editDisabled,
  isLoading,
}) => {
  const { t } = useTranslation();

  // const { receivedQuantity } = data || {};

  const finishedGoodTemplate = useWatch({
    name: `packingListDefenitions[${index}].finishedGoodTemplate`,
    defaultValue: defaultValue?.finishedGoodTemplate,
  });

  const finishedGoodInventoryItem = _.find(
    finishedGoodInventory,
    (item) => item.finishedGoodTemplate?._id === finishedGoodTemplate
  );

  const label = finishedGoodInventoryItem?.finishedGoodTemplate?.name || `${t('label.finishedGood')} #${index + 1}`;

  return (
    <>
      <Grid item xs={12} alignItems={'center'} container justifyContent={'space-between'}>
        <Grid item>
          <Typography display={'inline'} variant="subtitle1" color={'primary.main'}>
            {label}
          </Typography>
          {/* {status && (
            <Typography variant="subtitle1" color={'text.secondary'} display={'inline'}>
              {' '}
              ({t(`departmentRequestTasksStatus.${status}`)})
            </Typography>
          )}
          {_.isNumber(receivedQuantity) && (
            <Typography variant="subtitle2" color={'text.secondary'}>
              {t('purchaseOrderPage.form.title.received')} {receivedQuantity}
            </Typography>
          )} */}
        </Grid>
        <Grid item>
          <IconButton
            disabled={editDisabled}
            color={'error'}
            onClick={() => onRemove(index)}
            onMouseDown={() => onRemove(index)}
            edge="end"
          >
            <Iconify icon="carbon:trash-can" width={25} height={25} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <RHFAutocomplete
          disabled={editDisabled}
          name={`packingListDefenitions[${index}].finishedGoodTemplate`}
          label={t('label.finishedGood')}
          required
          freeSolo
          selectOnFocus
          clearOnBlur
          options={_.map(finishedGoodInventory, '_id')}
          getOptionLabel={(option) => _.find(finishedGoodInventory, { _id: option })?.finishedGoodTemplate?.name}
          getOptionDisabled={(option) => selectedFinishedGoods?.includes(option)}
          loading={isLoading}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <RHFTextField
          disabled={editDisabled}
          required
          type={'number'}
          name={`packingListDefenitions[${index}].quantity`}
          label={t('label.quantity')}
        />
      </Grid>
    </>
  );
};

FinishedGoodsFields.propTypes = {
  // data: PropTypes.object,
  finishedGoodInventory: PropTypes.array,
  defaultValue: PropTypes.object,
  onRemove: PropTypes.func,
  selectedFinishedGoods: PropTypes.array,
  index: PropTypes.number,
  editDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default FinishedGoodsFields;
