import PropTypes from 'prop-types';
// @mui
import { Box, Stack, MenuItem, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
// lodash
import _ from 'lodash';
// form
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// hooks
import useFormPersist from 'react-hook-form-persist';
// components
import { FormProvider, RHFAutocomplete, RHFSelect, RHFTextField } from '../../../../components/hook-form';
// auth
import { useAuth } from '../../../../providers/AuthProvider';

//-------------------------------------------------------------------------------------------------

const AddExpenseForm = ({ type, onAdd, onCreateExpensesType, expensesTypes, balances, sx }) => {
  const { t } = useTranslation();

  const { localStoragePrefix } = useAuth();
  const LOCAL_STORAGE_FORM_DATA_KEY = `${localStoragePrefix}_ADD_${type?.toUpperCase()}_EXPENSE_FORM_DATA`;

  // schema
  const schema = yup.object().shape({
    amount: yup
      .number()
      .moreThan(0, t('validation.required'))
      .typeError(t('validation.required'))
      .required(t('validation.required')),
    notes: yup
      .string()
      .notRequired()
      .min(1, t('validation.min', { value: 1 }))
      .max(500, t('validation.max', { value: 500 }))
      .nullable()
      .transform((value) => (value !== '' ? value : null)),
    type: yup.string().typeError(t('validation.required')).required(t('validation.required')),
    balance: yup.string().required(t('validation.required')),
  });

  const defaultValues = {
    amount: 0,
    notes: '',
    type: expensesTypes[0]?._id || '',
    balance: balances[0]?._id || '',
  };

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isValid },
  } = methods;

  // form persist
  useFormPersist(LOCAL_STORAGE_FORM_DATA_KEY, {
    watch: type ? watch : () => {},
    setValue,
    storage: window.localStorage,
  });

  const formatData = ({ amount, ...data }, amountField) => ({
    ...data,
    [amountField]: amount,
  });

  const onSubmit = (amountField) => {
    handleSubmit(
      (data) =>
        onAdd(formatData(data, amountField), () => {
          setValue('amount', defaultValues.amount);
          setValue('notes', defaultValues.notes);
        }),
      (errors) => console.log(errors)
    )();
  };

  const onAddCredit = () => onSubmit('credit');
  const onAddDebit = () => onSubmit('debit');

  return (
    <Box sx={sx}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <RHFTextField name="amount" type={'number'} label={t('expensesPage.form.field.amount')} required />
            <RHFAutocomplete
              name={'type'}
              label={t('expensesPage.form.field.type')}
              required
              options={_.map(expensesTypes, '_id')}
              getOptionLabel={(option) => _.find(expensesTypes, { _id: option })?.value}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              onAdd={onCreateExpensesType}
            />
            {balances.length > 0 && (
              <RHFSelect name={'balance'} label={t('expensesPage.form.field.balance')} required>
                {balances.map(({ _id, currency, symbol, amount }) => (
                  <MenuItem key={_id} value={_id}>
                    {`${currency}${amount < 0 ? ' -' : ' '}${symbol}${Math.abs(amount)}`}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
          </Stack>
          <RHFTextField name="notes" multiline rows={4} label={t('expensesPage.form.field.notes')} />

          {type && (
            <>
              <Divider />

              <Stack spacing={2} alignItems={'center'} justifyContent={'flex-end'} direction={'row'}>
                <LoadingButton
                  size="large"
                  onClick={type === 'credit' ? onAddCredit : onAddDebit}
                  variant="contained"
                  color={type === 'credit' ? 'error' : 'primary'}
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  {t(`expensesPage.button.${type}`)}
                </LoadingButton>
              </Stack>
            </>
          )}
        </Stack>
      </FormProvider>
    </Box>
  );
};

AddExpenseForm.propTypes = {
  type: PropTypes.oneOf(['debit', 'credit']),
  onAdd: PropTypes.func,
  onCreateExpensesType: PropTypes.func,
  expensesTypes: PropTypes.array,
  balances: PropTypes.array,
  sx: PropTypes.object,
};

export default AddExpenseForm;
