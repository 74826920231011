import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// material
import { Menu, IconButton } from '@mui/material';
// component
import Iconify from '../../../../components/Iconify';
import DownloadCsvTableMenuItem from './DownloadCsvTableMenuItem';
import TableMoreMenuItem from './TableMoreMenuItem';

// ----------------------------------------------------------------------

TableMoreMenu.propTypes = {
  onEdit: PropTypes.func,
  onOpenDetails: PropTypes.func,
  onDelete: PropTypes.func,
  onDownloadPdf: PropTypes.func,
  onDownloadCsv: PropTypes.func,
  csv: PropTypes.exact({
    data: PropTypes.array,
    headers: PropTypes.array,
    filename: PropTypes.string,
  }),
  onToggleIsActive: PropTypes.func,
  isActive: PropTypes.bool,
};

export default function TableMoreMenu({
  onDelete,
  onEdit,
  onOpenDetails,
  onDownloadPdf,
  onDownloadCsv,
  onToggleIsActive,
  isActive,
  csv,
}) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  if (!onDelete && !onEdit && !onOpenDetails && !onToggleIsActive && !onDownloadCsv && !onDownloadPdf) return null;

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="bi:three-dots-vertical" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {onEdit && (
          <TableMoreMenuItem onClick={onEdit} icon={'bi:pencil-square'}>
            {t('button.edit')}
          </TableMoreMenuItem>
        )}

        {onOpenDetails && (
          <TableMoreMenuItem onClick={onOpenDetails} icon={'bi:info-circle'}>
            {t('button.details')}
          </TableMoreMenuItem>
        )}

        {onToggleIsActive && (
          <TableMoreMenuItem
            onClick={onToggleIsActive}
            // icon={isActive ? 'bi:toggle-off' : 'bi:toggle-on'}
            icon={'bi:power'}
          >
            {isActive ? t('button.deactivate') : t('button.activate')}
          </TableMoreMenuItem>
        )}

        {onDownloadPdf && (
          <TableMoreMenuItem onClick={onDownloadPdf} icon={'bi:filetype-pdf'}>
            {t('button.downloadPDF')}
          </TableMoreMenuItem>
        )}

        {onDownloadCsv && (
          <TableMoreMenuItem onClick={onDownloadCsv} icon={'bi:filetype-csv'}>
            {t('button.downloadCSV')}
          </TableMoreMenuItem>
        )}

        {csv && <DownloadCsvTableMenuItem data={csv.data} headers={csv.headers} filename={csv.filename} />}

        {onDelete && (
          <TableMoreMenuItem onClick={onDelete} icon={'bi:trash'} color={'error.main'}>
            {t('button.delete')}
          </TableMoreMenuItem>
        )}
      </Menu>
    </>
  );
}
