import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import sessionApi from '../../api/sessionApi';

// ----------------------------------------------------------------------------

// THUNKS
export const Register = createAsyncThunk('session/register', async (data, thunkAPI) => {
  const res = await sessionApi.register(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const Login = createAsyncThunk('session/login', async (data, thunkAPI) => {
  const res = await sessionApi.login(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const Logout = createAsyncThunk('session/logout', async (__, thunkAPI) => {
  const res = await sessionApi.logout();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetMe = createAsyncThunk('session/me', async (__, thunkAPI) => {
  const res = await sessionApi.me();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

// state
const initialState = {
  email: null,
  role: null,
  fullName: null,
  isAuthentificated: null,
  loading: 'idle',
  // mocked
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
};

// slice
export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  extraReducers: (builder) => {
    // ------------------REGISTER-------------------------
    builder.addCase(Register.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(Register.fulfilled, (state) => ({
      ...state,
      loading: 'succeeded',
    }));

    builder.addCase(Register.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------LOGIN-------------------------
    builder.addCase(Login.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(Login.fulfilled, (state) => ({
      ...state,
      loading: 'succeeded',
    }));

    builder.addCase(Login.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));

    // ------------------ME-------------------------
    builder.addCase(GetMe.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(GetMe.fulfilled, (state, action) => ({
      ...state,
      ...action.payload.data,
      isAuthentificated: true,
      loading: 'succeeded',
    }));

    builder.addCase(GetMe.rejected, (state) => ({
      ...state,
      isAuthentificated: false,
      loading: 'failed',
    }));

    // ------------------LOGOUT-------------------------
    builder.addCase(Logout.pending, (state) => ({
      ...state,
      loading: 'pending',
    }));

    builder.addCase(Logout.fulfilled, () => ({
      ...initialState,
      isAuthentificated: false,
      loading: 'succeeded',
    }));

    builder.addCase(Logout.rejected, (state) => ({
      ...state,
      loading: 'failed',
    }));
  },
});

// Export Actions
// export const { } = userSlice.actions;

// Export Selectors
export const selectSessionUserEmail = (state) => state.session.email;
export const selectSessionUserFullName = (state) => state.session.fullName;
export const selectSessionUserRole = (state) => state.session.role;

export const selectIsAuthentificated = (state) => state.session.isAuthentificated;

export const selectSessionLoading = (state) => state.session.loading;

export const selectSessionUser = (state) => {
  // eslint-disable-next-line no-unused-vars
  const { loading, ...rest } = state.session;

  return rest;
};

export default sessionSlice.reducer;
