// lodash
import { get, filter } from 'lodash';

// -------------------------------------------------

export const descendingComparator = (a, b, orderBy) => {
  // TODO: Make sure that the default value '' works correctly (previously it was 0)
  const defaultValue = '';

  if ((get(b, orderBy) || defaultValue) < (get(a, orderBy) || defaultValue)) {
    return -1;
  }
  if ((get(b, orderBy) || defaultValue) > (get(a, orderBy) || defaultValue)) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const applySortFilter = (array, comparator, query, getFilteredItem) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (item) => {
      const filteredItem = getFilteredItem(item);

      let match = false;

      for (const key in filteredItem) {
        if (`${filteredItem[key]}`.toLowerCase().indexOf(query.toLowerCase()) !== -1) {
          match = true;
          break;
        }
      }

      return match;
    });
  }
  return stabilizedThis.map((el) => el[0]);
};
