import { createContext, useContext } from 'react';
import { PropTypes } from 'prop-types';
// cookies
// import { useCookies } from 'react-cookie';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { GetMe, Login, Logout, selectSessionUser, selectIsAuthentificated } from '../redux/reducers/SessionReducer';
// hooks
// import useLocalStorage from '../hooks/useLocalStorage';

//---------------------------------------------------

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const isAuthentificated = useSelector(selectIsAuthentificated);
  // const [isAuthentificatedCookie] = useCookies(['isAuthentificated']);

  const account = useSelector(selectSessionUser);

  // Auth data localStorage state
  // const [token, setToken, removeToken] = useLocalStorage('token', null, true);

  // // Remove all Auth data from localStorage
  // const clearLocalStorage = () => removeToken();

  // ME
  const handleGetMe = (callback) => {
    dispatch(GetMe()).then(() => {
      if (typeof callback === 'function') callback();
    });
  };

  // LOGIN
  const handleLogin = async (data, callback) => {
    dispatch(Login(data)).then((res) => {
      if (!res.error) {
        handleGetMe();
        if (typeof callback === 'function') callback();
      }
    });
  };

  // LOGOUT
  const handleLogout = (callback) => {
    dispatch(Logout()).then(() => {
      if (typeof callback === 'function') callback();
    });
  };

  const value = {
    onLogin: handleLogin,
    onLogout: handleLogout,
    onGetMe: handleGetMe,

    account,
    role: account?.role || null,
    email: account?.email || null,
    factory: account?.factory || null,
    factories: account?.factories || [],

    isAuthentificated,
    loading: isAuthentificated === null,

    localStoragePrefix: `${account.factory?._id}_${account.email?.toUpperCase()}`,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
