import PropTypes from 'prop-types';
// mui
import { Box, Stack, Tooltip, Typography } from '@mui/material';

// --------------------------------------------------------------

const Balance = ({ data }) => {
  const { currency, symbol, exchangeRate, amount } = data;

  return (
    <Tooltip title={`$${exchangeRate}`}>
      <Stack>
        <Box>
          <Typography display={'inline'} variant="h5">
            {currency}
          </Typography>
          {/* {exchangeRate !== 1 && (
          <Typography display={'inline'} color={'text.secondary'} variant="h5">
            {' '}
            ${exchangeRate}
          </Typography>
        )} */}
        </Box>
        <Typography display={'inline'} variant="body2" color={amount < 0 ? 'error.main' : 'text.secondary'}>
          {`${amount < 0 ? '-' : ''}${symbol}`}
          {Math.abs(Number(amount.toFixed(2)))}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

Balance.propTypes = {
  data: PropTypes.object,
};

export default Balance;
