import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const factoryOptionsApi = {
  // GET
  getByName: async (name) => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/factoryOptions/byName/${name}`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/factoryOptions`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/factoryOptions`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default factoryOptionsApi;
