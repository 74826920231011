import { LoadingStatusEnum } from '../../../constants/enums';

// ----------------------------------------------------------------

export default {
  list: (state) => state.packingList.list,
  loading: (state) => state.packingList.loading,
  isLoading: (state) => state.packingList.loading === LoadingStatusEnum.Pending,

  selectedItem: {
    data: (state) => state.packingList.selectedItem.data,
    loading: (state) => state.packingList.selectedItem.loading,
    isLoading: (state) => state.packingList.selectedItem.loading === LoadingStatusEnum.Pending,
    statusIsLoading: (state) => state.packingList.selectedItem.statusLoading === LoadingStatusEnum.Pending,
    invoiceIsLoading: (state) => state.packingList.selectedItem.invoiceLoading === LoadingStatusEnum.Pending,
  },

  distributors: {
    list: (state) => state.packingList.distributors.list,
    loading: (state) => state.packingList.distributors.loading,
    isLoading: (state) => state.packingList.distributors.loading === LoadingStatusEnum.Pending,
  },

  finishedGoodInventory: {
    list: (state) => state.packingList.finishedGoodInventory.list,
    loading: (state) => state.packingList.finishedGoodInventory.loading,
    isLoading: (state) => state.packingList.finishedGoodInventory.loading === LoadingStatusEnum.Pending,
  },

  groupedShippingBoxItems: {
    list: (state) => state.packingList.groupedShippingBoxItems.list,
    loading: (state) => state.packingList.groupedShippingBoxItems.loading,
    isLoading: (state) => state.packingList.groupedShippingBoxItems.loading === LoadingStatusEnum.Pending,
  },
};
