import PropTypes from 'prop-types';
// material
import { Checkbox, TableRow, TableHead as MuiTableHead, Skeleton } from '@mui/material';
// components
import TableCell from './TableCell';
import TableHeadCell from './TableHeadCell';

// ----------------------------------------------------------------------

const TableHead = ({
  isLoading,
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  size,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const SelectAllCheckbox = (
    <Checkbox
      indeterminate={numSelected > 0 && numSelected < rowCount}
      checked={rowCount > 0 && numSelected === rowCount}
      onChange={onSelectAllClick}
    />
  );

  return (
    <MuiTableHead>
      <TableRow>
        {onSelectAllClick && (
          <TableCell size={size} padding="checkbox">
            {isLoading ? <Skeleton width={25}>{SelectAllCheckbox}</Skeleton> : SelectAllCheckbox}
          </TableCell>
        )}
        {headLabel.map((headCell) => (
          <TableHeadCell
            key={headCell.id}
            size={size}
            data={headCell}
            orderBy={orderBy}
            order={order}
            createSortHandler={createSortHandler}
            isLoading={isLoading}
          />
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

TableHead.propTypes = {
  isLoading: PropTypes.bool,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

TableHead.defaultProps = {
  numSelected: 0,
  size: 'md',
};

export default TableHead;
