import PropTypes from 'prop-types';
// @mui
import { Typography } from '@mui/material';

// -------------------------------------------------------------------------

const DataCardTitle = ({ children }) => (
  <Typography textTransform={'uppercase'} variant="body1" color={'text.secondary'} noWrap>
    {children}
  </Typography>
);

DataCardTitle.propTypes = {
  children: PropTypes.node,
};

export default DataCardTitle;
