// @mui
import { Button, Container, Stack, Typography } from '@mui/material';
// lodash
import _ from 'lodash';
import { useEffect, useState } from 'react';
// i18n
import { useTranslation } from 'react-i18next';
// redux
import { useDispatch, useSelector } from 'react-redux';

import ConfirmationDialog from '../components/ConfirmationDialog';
import Iconify from '../components/Iconify';
// components
import Page from '../components/Page';
import StyledModal from '../components/StyledModal';
import { ClearGroupedShippingBoxItems, ClearSelectedPackingList } from '../redux/reducers/packingList/actions';
// selectors
import selectors from '../redux/reducers/packingList/selectors';
// reducers
import {
  ChangePackingListStatus,
  CreatePackingList,
  CreatePackingListInvoice,
  DeletePackingList,
  DownloadPackingListCsv,
  GetFinishedGoodInventory,
  GetGroupedShippingBoxItems,
  GetPackingListById,
  GetPackingLists,
  UpdatePackingList,
} from '../redux/reducers/packingList/thunks';
// sections
import AddEditForm from '../sections/@dashboard/packingLists/AddEditForm';
import PackingListDetails from '../sections/@dashboard/packingLists/Details';
import StatusIcon from '../sections/@dashboard/packingLists/StatusIcon';
import PackingListsTable from '../sections/@dashboard/packingLists/Table';

// ---------------------------------------------------------------------------

export default function PackingLists() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Redux State
  const selectedItem = useSelector(selectors.selectedItem.data);
  const selectedItemIsLoading = useSelector(selectors.selectedItem.isLoading);
  const selectedItemStatusIsLoading = useSelector(selectors.selectedItem.statusIsLoading);
  const selectedItemInvoiceIsLoading = useSelector(selectors.selectedItem.invoiceIsLoading);

  const list = useSelector(selectors.list);
  const loading = useSelector(selectors.loading);
  const isLoading = useSelector(selectors.isLoading);

  const distributors = useSelector(selectors.distributors.list);
  const distributorsIsLoading = useSelector(selectors.distributors.isLoading);

  const finishedGoodInventory = useSelector(selectors.finishedGoodInventory.list);
  const finishedGoodInventoryIsLoading = useSelector(selectors.finishedGoodInventory.isLoading);

  const groupedShippingBoxItems = useSelector(selectors.groupedShippingBoxItems.list);
  const groupedShippingBoxItemsIsLoading = useSelector(selectors.groupedShippingBoxItems.isLoading);

  const { plNumber, status } = selectedItem || {};
  const plNumbers = _.map(list, 'plNumber');
  const usedPlNumbers = plNumber ? plNumbers.filter((code) => code !== plNumber) : plNumbers;

  // Modal
  // --state
  const [modalType, setModalType] = useState(null);

  const onOpenModal = (id) => {
    // onGetDistributors();
    onGetFinishedGoodInventory();

    if (id) onGetById(id);
    setModalType(id ? 'edit' : 'add');
  };

  const onCloseModal = () => {
    if (modalType === 'edit') onClearSelectedItem();
    setModalType(null);
  };

  // --title
  const [modalTitle, setModalTitle] = useState(null);

  useEffect(() => {
    switch (modalType) {
      case 'add':
        setModalTitle(t('purchaseOrderPage.addModal.title'));
        break;
      case 'edit':
        setModalTitle(
          plNumber
            ? t('purchaseOrderPage.editModal.title_with_value', { value: plNumber })
            : t('purchaseOrderPage.editModal.title')
        );
        break;
      default:
        setModalTitle(null);
        break;
    }
  }, [modalType, plNumber, t]);

  // Details Modal
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);

  const onOpenDetails = (id) => {
    onGetById(id);
    onGetGroupedShippingBoxItems(id);
    setDetailsModalOpen(true);
  };

  const onCloseDetails = () => {
    onClearSelectedItem();
    onClearGroupedShippingBoxItems();
    setDetailsModalOpen(false);
  };

  // Delete Confirmation
  const [itemToDelete, setItemToDelete] = useState(null);
  const closeDeleteConfirmation = () => setItemToDelete(null);
  const openDeleteConfirmation = (id) => setItemToDelete(id);

  const onDeleteConfirmationAgree = () => {
    if (itemToDelete) onDelete(itemToDelete);
  };

  // Methods
  // --packing list
  const onGet = () => {
    dispatch(GetPackingLists());
  };

  const onGetById = (id) => {
    dispatch(GetPackingListById(id));
  };

  const onClearSelectedItem = () => {
    dispatch(ClearSelectedPackingList());
  };

  const onClearGroupedShippingBoxItems = () => {
    dispatch(ClearGroupedShippingBoxItems());
  };

  const onDelete = (id) => {
    dispatch(DeletePackingList(id));
  };

  const onCreate = async (data) => {
    const res = await dispatch(CreatePackingList(data));
    if (res?.payload?.status === 'success') {
      onCloseModal();
    }
  };

  const onChangeStatus = (id, status) => {
    dispatch(ChangePackingListStatus({ id, status }));
  };

  const onCreateInvoice = (id) => {
    dispatch(CreatePackingListInvoice(id));
  };

  const onUpdate = async (data, id) => {
    const res = await dispatch(UpdatePackingList({ data, id }));
    if (res?.payload?.status === 'success') {
      onCloseModal();
    }
  };

  const onDownloadCsv = async (id) => {
    dispatch(DownloadPackingListCsv(id));
  };

  // --finished good inventory
  const onGetFinishedGoodInventory = () => {
    dispatch(GetFinishedGoodInventory());
  };

  // --grouped shipping box items
  const onGetGroupedShippingBoxItems = (id) => {
    dispatch(GetGroupedShippingBoxItems(id));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onGet, []);

  return (
    <>
      <Page title={t('pages.purchaseOrders')}>
        <Container>
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" gutterBottom>
              {t('pages.purchaseOrders')}
            </Typography>

            <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
              <Button variant="contained" onClick={() => onOpenModal()} startIcon={<Iconify icon="eva:plus-fill" />}>
                {t('button.newOrder')}
              </Button>
            </Stack>
          </Stack>

          <PackingListsTable
            loading={loading}
            list={list}
            onEdit={onOpenModal}
            onOpenDetails={onOpenDetails}
            onDelete={openDeleteConfirmation}
            onDownloadCsv={onDownloadCsv}
          />
        </Container>
      </Page>
      <StyledModal
        maxWidth={'md'}
        title={modalTitle}
        subtitle={
          selectedItem?.status ? (
            <Stack spacing={1} direction={'row'}>
              <span>{t(`packingListStatusEnum.${selectedItem?.status}`)}</span>
              <StatusIcon status={selectedItem?.status} />
            </Stack>
          ) : null
        }
        open={Boolean(modalType)}
        handleClose={onCloseModal}
        isLoading={selectedItemIsLoading || finishedGoodInventoryIsLoading}
      >
        <AddEditForm
          type={modalType}
          distributors={distributors}
          distributorIsLoading={distributorsIsLoading}
          finishedGoodInventory={finishedGoodInventory}
          finishedGoodInventoryIsLoading={finishedGoodInventoryIsLoading}
          isLoading={isLoading}
          dataToEdit={selectedItem}
          onAdd={onCreate}
          onEdit={onUpdate}
          usedPlNumbers={usedPlNumbers}
        />
      </StyledModal>

      <StyledModal
        fullScreen
        title={t('purchaseOrderPage.details.title', { value: plNumber ? `#${plNumber}` : '' })}
        subtitle={
          status ? (
            <Stack spacing={1} direction={'row'}>
              <span>{t(`packingListStatusEnum.${status}`)}</span>
              <StatusIcon status={status} />
            </Stack>
          ) : null
        }
        open={isDetailsModalOpen}
        handleClose={onCloseDetails}
        isLoading={selectedItemIsLoading || groupedShippingBoxItemsIsLoading}
      >
        <PackingListDetails
          data={selectedItem}
          groupedShippingBoxItems={groupedShippingBoxItems}
          onChangeStatus={onChangeStatus}
          statusIsLoading={selectedItemStatusIsLoading}
          onCreateInvoice={onCreateInvoice}
          invoiceIsLoading={selectedItemInvoiceIsLoading}
        />
      </StyledModal>

      <ConfirmationDialog
        open={itemToDelete !== null}
        onAgree={onDeleteConfirmationAgree}
        onDisagree={closeDeleteConfirmation}
        onClose={closeDeleteConfirmation}
        title={t('dialog.confirmation.delete.title')}
        text={t('dialog.confirmation.delete.purchaseOrder.text')}
        agreeText={t('dialog.confirmation.delete.agree')}
        disagreeText={t('dialog.confirmation.delete.disagree')}
        agreeColor={'error'}
      />
    </>
  );
}
