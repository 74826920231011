import { createAsyncThunk } from '@reduxjs/toolkit';

// api
import usersApi from '../../../api/usersApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'users';

export const GetUsers = createAsyncThunk(`${REDUCER_NAME}/getUsers`, async ({ withoutFactory }, thunkAPI) => {
  const res = await usersApi.get();

  if (res.status === 200) return { ...res.data, withoutFactory };

  return thunkAPI.rejectWithValue();
});

export const CreateUser = createAsyncThunk(`${REDUCER_NAME}/createUser`, async (data, thunkAPI) => {
  const res = await usersApi.create(data);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateUser = createAsyncThunk(`${REDUCER_NAME}/updateUser`, async ({ data, id }, thunkAPI) => {
  const res = await usersApi.update(data, id);

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});