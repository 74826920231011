import PropTypes from 'prop-types';
// material
import { Grid, IconButton, InputAdornment, OutlinedInput, Toolbar, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// i18n
import { useTranslation } from 'react-i18next';
// component
import DownloadCsvIconButton from '../../../components/DownloadCsvIconButton';
import Iconify from '../../../components/Iconify';
import SwitchWithTooltip from '../../../components/SwitchWithTooltip';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TableToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func,
  onDownloadExcel: PropTypes.func,
  onDownloadPdf: PropTypes.func,
  csv: PropTypes.exact({
    data: PropTypes.array,
    headers: PropTypes.array,
    filename: PropTypes.string,
    canDownloadIfEmptyData: PropTypes.bool,
  }),
  children: PropTypes.node,
};

TableToolbar.defaultProps = {
  numSelected: 0,
};

export default function TableToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDelete,
  onDownloadExcel,
  onDownloadPdf,
  csv,
  children,
}) {
  const { t } = useTranslation();

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <Grid container>
        <Grid container item xs={4} md={6} spacing={1} alignItems={'center'}>
          {numSelected > 0 ? (
            <Typography component="div" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : onFilterName ? (
            <SearchStyle
              value={filterName}
              onChange={onFilterName}
              placeholder={t('search.search')}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          ) : null}
        </Grid>

        <Grid container item xs={8} md={6} spacing={1} alignItems={'center'} justifyContent={'flex-end'}>
          {children}

          {onDownloadPdf && (
            <TableToolbarIconButton text={t('button.downloadPDF')} onClick={onDownloadPdf} icon="bi:filetype-pdf" />
          )}

          {onDownloadExcel && (
            <TableToolbarIconButton text={t('button.download')} onClick={onDownloadExcel} icon="bi:filetype-xlsx" />
          )}

          {csv && (
            <TableToolbarItemWrapper>
              <DownloadCsvIconButton
                data={csv.data}
                canDownloadIfEmptyData={csv.canDownloadIfEmptyData}
                headers={csv.headers}
                filename={csv.filename}
              />
            </TableToolbarItemWrapper>
          )}

          {numSelected > 0 && (
            <>
              {onDelete && (
                <TableToolbarIconButton text={t('button.delete')} onClick={onDelete} icon="eva:trash-2-fill" />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </RootStyle>
  );
}

export const TableToolbarItemWrapper = ({ children }) => (
  <Grid item xs={6} sm={'auto'} container justifyContent={'center'}>
    {children}
  </Grid>
);

TableToolbarItemWrapper.propTypes = {
  children: PropTypes.node,
};

export const TableToolbarIconButton = ({ text, onClick, color, icon, disabled }) => (
  <TableToolbarItemWrapper>
    <Tooltip title={text || ''}>
      <span>
        <IconButton onClick={onClick} color={color || 'primary'} disabled={disabled}>
          <Iconify icon={icon} width={25} height={25} />
        </IconButton>
      </span>
    </Tooltip>
  </TableToolbarItemWrapper>
);

TableToolbarIconButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const TableToolbarCheckbox = ({ value, text, onChange, icon, color, iconColor, sx }) => (
  <TableToolbarItemWrapper>
    <SwitchWithTooltip
      size={25}
      value={value}
      onChange={onChange}
      tooltipText={text}
      icon={icon}
      color={color}
      iconColor={iconColor}
      sx={sx}
    />
  </TableToolbarItemWrapper>
);

TableToolbarCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  sx: PropTypes.object,
};
