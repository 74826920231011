import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import { Box, Divider, Grid, MenuItem, Stack } from '@mui/material';
// lodash
import _ from 'lodash';
import PropTypes from 'prop-types';
// form
import { useForm } from 'react-hook-form';
// i18n
import { useTranslation } from 'react-i18next';

// components
import { FormProvider, RHFAutocomplete, RHFSelect, RHFTextField } from '../../../../components/hook-form';
// enums
import { PackingListStatusEnum } from '../../../../constants/enums';
// utils
import getNextModelCode from '../../../../utils/getNextModelCode';
import getDefaultValues, { mapPackingListDefenitionsToFormValues } from './defaultValues';
import FinishedGoods from './FinishedGoods';
import getSchema from './yupSchema';

//-------------------------------------------------------------------------------------------------

const AddEditForm = ({
  type,
  distributors,
  distributorIsLoading,
  finishedGoodInventory,
  finishedGoodInventoryIsLoading,
  isLoading,
  dataToEdit,
  onAdd,
  onEdit,
  usedPlNumbers,
}) => {
  const { t } = useTranslation();

  const { status, packingListDefenitions, _id } = dataToEdit || {};

  const editDisabled = status && status !== PackingListStatusEnum.Pending;
  const showStatusSelect =
    type === 'add' || (status && ![PackingListStatusEnum.Shipped, PackingListStatusEnum.Invoiced].includes(status));

  const defaultValues = getDefaultValues(
    dataToEdit
      ? {
          distributor: dataToEdit.distributor?._id,
          plNumber: dataToEdit.plNumber,
          notes: dataToEdit.notes,
          status: dataToEdit.status,
          packingListDefenitions: packingListDefenitions.map(mapPackingListDefenitionsToFormValues),
        }
      : {
          plNumber: getNextModelCode(usedPlNumbers),
          status: PackingListStatusEnum.Pending,
        }
  );
  const schema = getSchema(t);

  const methods = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
    context: { usedPlNumbers },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    if (type === 'add') await onAdd(data);
    if (type === 'edit') await onEdit(data, _id);
  };

  const onError = (err) => console.error(err);

  return (
    <Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={12}>
            <RHFTextField
              disabled={editDisabled}
              name={'plNumber'}
              type={'number'}
              label={t('purchaseOrderPage.form.field.plNumber')}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <RHFTextField multiline rows={4} disabled={editDisabled} name={'notes'} label={t('label.notes')} />
          </Grid>

          <FinishedGoods
            data={packingListDefenitions}
            finishedGoodInventory={finishedGoodInventory}
            isLoading={finishedGoodInventoryIsLoading}
            editDisabled={editDisabled}
            defaultValue={defaultValues.packingListDefenitions}
          />

          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Stack justifyContent={'flex-end'} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                disabled={[PackingListStatusEnum.Shipped, PackingListStatusEnum.Invoiced].includes(status)}
                loading={isSubmitting || !type || isLoading}
              >
                {type === 'add' ? t('button.newOrder') : t('button.updateOrder')}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </Box>
  );
};

AddEditForm.propTypes = {
  type: PropTypes.oneOf(['edit', 'add']),
  distributors: PropTypes.array,
  distributorIsLoading: PropTypes.bool,
  finishedGoodInventory: PropTypes.array,
  finishedGoodInventoryIsLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataToEdit: PropTypes.object,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  usedPlNumbers: PropTypes.array,
};

export default AddEditForm;
