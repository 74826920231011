import { useState, useEffect } from 'react';
// material
import { Stack, Container, Typography, Grid, Button } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';
// redux
import { useDispatch, useSelector } from 'react-redux';
import {
  selectExpensesBalances,
  selectExpensesList,
  // selectExpensesLoading,
  selectExpensesTypes,
  GetExpenses,
  CreateExpense,
  CreateExpensesType,
} from '../redux/reducers/ExpensesReducer';
// components
import Page from '../components/Page';
import DateRange from '../components/hook-form/forms/DateRange';
import StyledModal from '../components/StyledModal';
// sections
import ExpensesTable from '../sections/@dashboard/expenses/ExpensesTable';
import Balances from '../sections/@dashboard/expenses/balances';
import AddExpenseForm from '../sections/@dashboard/expenses/addExpenseForm';
// auth
import { useAuth } from '../providers/AuthProvider';

// ----------------------------------------------------------------------

export default function Expenses() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { localStoragePrefix } = useAuth();
  const LOCAL_STORAGE_PAGE_KEY = `${localStoragePrefix}_EXPENSES`;

  // const loading = useSelector(selectExpensesLoading);
  const list = useSelector(selectExpensesList);
  const balances = useSelector(selectExpensesBalances);
  const expensesTypes = useSelector(selectExpensesTypes);

  const [datesInRange, setDatesInRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [filename, setFilename] = useState(null);

  useEffect(() => {
    if (datesInRange.startDate && datesInRange.endDate) {
      const startDateString = t('date.short', { date: datesInRange.startDate });
      const endDateString = t('date.short', { date: datesInRange.endDate });

      setFilename(`${t('pages.expenses').toUpperCase()}_${startDateString}_${endDateString}`);
    }
  }, [datesInRange, t]);

  const onGetExpenses = ({ startDate, endDate }) => {
    setDatesInRange({ startDate, endDate });
    dispatch(GetExpenses({ startDate, endDate }));
  };

  const onCreateExpense = async (data, cb) => {
    const res = await dispatch(CreateExpense(data));

    if (res.payload.status === 'success' && typeof cb === 'function') cb();
  };

  const onCreateExpensesType = async (type) => {
    const res = await dispatch(CreateExpensesType(type));

    return res?.payload?.data?._id || null;
  };

  const [modalState, setModalState] = useState({ open: false, type: null });
  const onOpenModal = (type) =>
    setModalState({
      open: true,
      type,
    });
  const onOpenCreditModal = () => onOpenModal('credit');
  const onOpenDebitModal = () => onOpenModal('debit');
  const onCloseModal = () => setModalState({ open: false, type: null });

  return (
    <>
      <Page title={t('pages.expenses')}>
        <Container>
          <Grid spacing={2} container>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" gutterBottom>
                {t('pages.expenses')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <DateRange onSubmit={onGetExpenses} page={'EXPENSES'} />
            </Grid>
          </Grid>
          <Stack sx={{ my: 2 }} spacing={2}>
            <Stack
              spacing={3}
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={'center'}
              justifyContent={'flex-end'}
            >
              <Stack spacing={3} direction={'row'}>
                <Button size="large" fullWidth onClick={onOpenCreditModal} variant="contained" color={'error'}>
                  {t('expensesPage.button.credit')}
                </Button>
                <Button size="large" fullWidth onClick={onOpenDebitModal} variant="contained">
                  {t('expensesPage.button.debit')}
                </Button>
              </Stack>
              <Balances list={balances} />
            </Stack>
            <ExpensesTable localStorageKey={LOCAL_STORAGE_PAGE_KEY} filename={filename} list={list} />
          </Stack>
        </Container>
      </Page>
      <StyledModal
        maxWidth={'sm'}
        open={modalState.open}
        handleClose={onCloseModal}
        title={modalState.type ? t(`expensesPage.button.${modalState.type}`) : ''}
      >
        <AddExpenseForm
          sx={{ mt: 3 }}
          type={modalState.type}
          onAdd={onCreateExpense}
          onCreateExpensesType={onCreateExpensesType}
          expensesTypes={expensesTypes}
          balances={balances}
        />
      </StyledModal>
    </>
  );
}
