import PropTypes from 'prop-types';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Iconify from '../../../../components/Iconify';

// -------------------------------------------------------------------------------------------

const TableMoreMenuItem = ({ onClick, color, icon, width, height, variant, children }) => (
  <MenuItem onClick={onClick} sx={{ color }}>
    <ListItemIcon>
      <Iconify icon={icon} width={width} height={height} sx={{ color }} />
    </ListItemIcon>
    <ListItemText primary={children} primaryTypographyProps={{ variant }} />
  </MenuItem>
);

TableMoreMenuItem.defaultProps = {
  color: 'text.secondary',
  width: 24,
  height: 25,
  variant: 'body2',
};

TableMoreMenuItem.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  variant: PropTypes.string,
  children: PropTypes.node,
};

export default TableMoreMenuItem;
