import PropTypes from 'prop-types';
// mui
import { Box, TableSortLabel, Skeleton } from '@mui/material';
// components
import TableCell from './TableCell';

// ----------------------------------------------------------------------------

export const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// -----------------------------------------------------------------------------

const TableHeadCell = ({ size, data, orderBy, order, createSortHandler, isLoading }) => {
  const { id, label, align, alignRight, disableSort } = data;

  const alignment = align || (alignRight ? 'right' : 'left');

  const HeadLabel = (
    <TableSortLabel
      hideSortIcon
      active={disableSort ? false : orderBy === id}
      direction={orderBy === id ? order : 'asc'}
      onClick={disableSort ? null : createSortHandler(id)}
    >
      {label}
      {orderBy === id ? (
        <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
      ) : null}
    </TableSortLabel>
  );

  return (
    <TableCell size={size} bold align={alignment} sortDirection={orderBy === id ? order : false}>
      {isLoading ? (
        <Box width={'100%'} display={'flex'} justifyContent={alignment}>
          <Skeleton>{HeadLabel}</Skeleton>
        </Box>
      ) : (
        HeadLabel
      )}
    </TableCell>
  );
};

TableHeadCell.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  createSortHandler: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
};

export default TableHeadCell;
