import PropTypes from 'prop-types';
// mui
import { Stack } from '@mui/material';
// components
import Balance from './Balance';

// --------------------------------------------------------------

const Balances = ({ list }) => (
  <Stack spacing={3} direction={'row'}>
    {list.map((el) => (
      <Balance key={el._id} data={el} />
    ))}
  </Stack>
);

Balances.propTypes = {
  list: PropTypes.array,
};

export default Balances;
