// lodash
import { groupBy, isArray, isBoolean, keyBy } from 'lodash';
import { PropTypes } from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// api
import factoryOptionsApi from '../api/factoryOptionsApi';
// auth
import { useAuth } from './AuthProvider';

//---------------------------------------------------

const FactoryOptionsContext = createContext(null);

const FactoryOptionsProvider = ({ children }) => {
  const { isAuthentificated } = useAuth();

  const [factoryOptions, setFactoryOptions] = useState({});
  const [rooms, setFactoryRooms] = useState([]);

  const location = useLocation();

  const getOptions = async () => {
    const [optionsRes] = await Promise.all([factoryOptionsApi.get()]);

    if (optionsRes.status === 200 && isArray(optionsRes.data?.data)) {
      const optionsObject = groupBy(optionsRes.data.data, 'optionName');
      const factoryConfigObject = keyBy(optionsObject?.factoryConfig, 'name');

      setFactoryOptions({
        ...optionsObject,
        ...factoryConfigObject,
      });
    }
  };

  useEffect(() => {
    if (isAuthentificated) getOptions();
  }, [location, isAuthentificated]);

  const value = {
    factoryOptions,
    rooms,
    refetch: getOptions,
  };

  return <FactoryOptionsContext.Provider value={value}>{children}</FactoryOptionsContext.Provider>;
};

FactoryOptionsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export const useFactoryOptions = () => useContext(FactoryOptionsContext);

export const useFactoryConfigValue = (name) => {
  const { factoryOptions } = useFactoryOptions() || {};
  const configItem = factoryOptions ? factoryOptions[name] : null;

  return isBoolean(configItem?.value) ? configItem.value : configItem?.value || null;
};

export default FactoryOptionsProvider;
