// date
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// i18n
import { useTranslation } from 'react-i18next';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import Alert from './components/alert';
// date locales
import { dateLocales } from './locales/i18n';
// hooks
import { useGAPageTracking } from './hooks/useGAPageTracking';

// ----------------------------------------------------------------------

export default function App() {
  const { i18n } = useTranslation();

  useGAPageTracking();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateLocales[i18n.language || window.localStorage.i18nextLng || 'en']}
    >
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
        <Alert />
      </ThemeProvider>
    </LocalizationProvider>
  );
}
