import PropTypes from 'prop-types';
// components
import Iconify from '../../../components/Iconify';
// enums
import { PackingListStatusEnum } from '../../../constants/enums';

// -------------------------------------------------------------------------------

const StatusIcon = ({ status, ...rest }) => {
  let statusColor = 'text.secondary';
  let statusIcon = null;

  switch (status) {
    case PackingListStatusEnum.Pending:
      statusIcon = 'carbon:pending';
      break;
    case PackingListStatusEnum.ReadyForCollection:
      statusIcon = 'carbon:checkmark-outline';
      break;
    case PackingListStatusEnum.Collected:
      statusColor = 'success.main';
      statusIcon = 'carbon:checkmark-outline';
      break;
      case PackingListStatusEnum.Invoiced:
        statusColor = 'success.main';
        statusIcon = 'basil:invoice-outline';
        break;
    case PackingListStatusEnum.Shipped:
      statusColor = 'success.main';
      statusIcon = 'mdi:truck-check-outline';
      break;

    default:
      break;
  }

  return <Iconify icon={statusIcon} color={statusColor} height={25} width={25} {...rest} />;
};

StatusIcon.propTypes = {
  status: PropTypes.string,
};

export default StatusIcon;
