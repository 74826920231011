// mui
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
// i18n
import { useTranslation } from 'react-i18next';

// components
import { DataCardRow, DataCardSection } from '../../../components/DataCardSection';
// utils
import { getCigarLabel } from '../../../utils/modelLabels';

//-------------------------------------------------------------------------------------------------

const FinishedGoodItemDetails = ({ data }) => {
  const { t } = useTranslation();

  const { finishedGoodTemplate, quantity, costPerProduct } = data || {};
  const { name, sku, capacity, unitOfMeasure, category, cigars, rawMaterials } = finishedGoodTemplate || {};

  return (
    <Stack spacing={2}>
      <DataCardSection>
        <DataCardRow title={t('label.name')} text={name} titleIconProps={{ icon: 'ion:document-text-outline' }} />
        <DataCardRow title={t('label.sku')} text={sku} titleIconProps={{ icon: 'ion:barcode-outline' }} />

        <DataCardRow title={t('label.quantity')} text={`${quantity}`} titleIconProps={{ icon: 'tabler:numbers' }} />
        <DataCardRow
          title={t('table.costPerProduct')}
          text={`${(costPerProduct || 0).toFixed(2)}`}
          titleIconProps={{ icon: 'ion:wallet-outline' }}
        />
      </DataCardSection>

      {cigars?.map((item, idx) => {
        const { _id, cigar, quantityPerProduct } = item;

        return (
          <DataCardSection key={_id} title={t('finishedGoodInventory.details.sectionTitle.cigar', { value: idx + 1 })}>
            <DataCardRow
              title={t('label.name')}
              text={getCigarLabel(cigar)}
              titleIconProps={{ icon: 'ion:document-text-outline' }}
            />
            <DataCardRow title={t('label.sku')} text={sku} titleIconProps={{ icon: 'ion:barcode-outline' }} />
            <DataCardRow
              title={t('label.quantityPerProduct')}
              text={`${(quantityPerProduct || 0).toFixed(2)}`}
              titleIconProps={{ icon: 'tabler:numbers' }}
            />
          </DataCardSection>
        );
      })}

      {rawMaterials?.map((item, idx) => {
        const { _id, rawMaterialTemplate, quantityPerProduct } = item;
        const { name, category, sku, costPerUnit, purpose, unitOfMeasure, usageUnit, supplier } =
          rawMaterialTemplate || {};

        return (
          <DataCardSection
            key={_id}
            title={t('finishedGoodInventory.details.sectionTitle.rawMaterial', { value: idx + 1 })}
          >
            <DataCardRow title={t('label.name')} text={name} titleIconProps={{ icon: 'ion:document-text-outline' }} />
            <DataCardRow title={t('label.category')} text={category} titleIconProps={{ icon: 'ion:layers-outline' }} />
            <DataCardRow title={t('label.sku')} text={sku} titleIconProps={{ icon: 'ion:barcode-outline' }} />
            <DataCardRow
              title={t('label.quantityPerProduct')}
              text={`${(quantityPerProduct || 0).toFixed(2)}`}
              titleIconProps={{ icon: 'tabler:numbers' }}
            />
            <DataCardRow
              title={t('table.costPerUnit')}
              text={`${(costPerUnit || 0).toFixed(2)}`}
              titleIconProps={{ icon: 'ion:wallet-outline' }}
            />
            <DataCardRow title={t('table.purpose')} text={purpose} titleIconProps={{ icon: 'ion:move-outline' }} />
            <DataCardRow title={t('table.unitOfMeasure')} text={unitOfMeasure} titleIconProps={{ icon: 'mdi:ruler' }} />
          </DataCardSection>
        );
      })}
    </Stack>
  );
};

FinishedGoodItemDetails.propTypes = {
  data: PropTypes.object,
};

export default FinishedGoodItemDetails;
