import * as yup from 'yup';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// i18n
import { useTranslation } from 'react-i18next';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// hooks
import { useAuth } from '../../../providers/AuthProvider';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  const { onLogin } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email(t('validation.email'))
      .min(3, t('validation.min', { value: 3 }))
      .max(254, t('validation.max', { value: 254 }))
      .required(t('validation.required')),
    password: yup
      .string()
      .min(6, t('validation.min', { value: 3 }))
      .max(32, t('validation.max', { value: 32 }))
      .required(t('validation.required')),
  });

  const defaultValues = {
    email: '',
    password: '',
    // remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    onLogin(data);
    // navigate('/dashboard', { replace: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label={t('session.email')} />

        <RHFTextField
          name="password"
          label={t('session.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton sx={{ mt: 2 }} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        {t('session.login')}
      </LoadingButton>
    </FormProvider>
  );
}
