import PropTypes from 'prop-types';
// @mui
import { Card, CardContent, Divider, Stack } from '@mui/material';
// components
import DataCardTitle from './DataCardTitle';

// -------------------------------------------------------------------------

const DataCardSection = ({ title, rowsSpacing, hideDivider, children }) => (
  <Stack spacing={1}>
    {Boolean(title) && <DataCardTitle>{title}</DataCardTitle>}
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={rowsSpacing} divider={!hideDivider ? <Divider /> : null}>
          {children}
        </Stack>
      </CardContent>
    </Card>
  </Stack>
);

DataCardSection.defaultProps = {
  rowsSpacing: 2,
};

DataCardSection.propTypes = {
  title: PropTypes.string,
  rowsSpacing: PropTypes.number,
  hideDivider: PropTypes.bool,
  children: PropTypes.node,
};

export default DataCardSection;
