// mui
import { Collapse, IconButton, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

// components
import Iconify from '../../../../components/Iconify';
import { TableCell } from '../../table';
import FinishedGoodSubitemsTable from './FinishedGoodSubitemsTable';

// -------------------------------------------------------------------------------------------

const FinishedGoodInventoryTableRow = ({ isLoading, data, onEdit, columnCount }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const { totalQuantity, booked, totalCost, finishedGoodTemplate, averageCostPerProduct, subitems } = data;
  const { name, sku } = finishedGoodTemplate;

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }} onClick={toggleOpen}>
        <TableCell>
          <IconButton size="small" onClick={toggleOpen}>
            <Iconify
              icon={
                open ? 'material-symbols:keyboard-arrow-up-rounded' : 'material-symbols:keyboard-arrow-down-rounded'
              }
              width={30}
              height={30}
            />
          </IconButton>
        </TableCell>
        <TableCell align="left" isLoading={isLoading}>
          {name}
        </TableCell>
        <TableCell align="left" isLoading={isLoading}>
          {sku}
        </TableCell>
        <TableCell align="left" isLoading={isLoading}>
          {(averageCostPerProduct || 0).toFixed(2)}
        </TableCell>
        <TableCell align="left" isLoading={isLoading}>
          {totalQuantity}
        </TableCell>
        <TableCell align="left" isLoading={isLoading}>
          {booked}
        </TableCell>
        <TableCell align="left" isLoading={isLoading}>
          {(totalCost || 0).toFixed(2)}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ p: 0, pl: 2 }} colSpan={columnCount}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <FinishedGoodSubitemsTable list={subitems} isLoading={isLoading} onEdit={onEdit} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

FinishedGoodInventoryTableRow.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  columnCount: PropTypes.number,
  onEdit: PropTypes.func,
};

export default FinishedGoodInventoryTableRow;
