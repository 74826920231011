// component
import Iconify from '../../components/Iconify';
// access
import { routesAccess } from '../../services/access';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'admin',
    icon: getIcon('clarity:administrator-solid'),
    roles: [...routesAccess.factories],
    children: [
      {
        title: 'factories',
        path: '/dashboard/factories',
        icon: getIcon('ic:round-factory'),
        roles: routesAccess.factories,
      },
    ],
  },
  {
    title: 'purchaseOrders',
    path: '/dashboard/purchase-orders',
    icon: getIcon('fa6-solid:boxes-packing'),
    roles: routesAccess.packingLists,
  },
  // {
  //   title: 'finances',
  //   icon: getIcon('mdi:wallet'),
  //   roles: [...routesAccess.invoices, ...routesAccess.expenses],
  //   children: [
  //     {
  //       title: 'invoices',
  //       path: '/dashboard/invoices',
  //       icon: getIcon('mdi:invoice'),
  //       roles: routesAccess.invoices,
  //       info: getIcon('material-symbols:av-timer'),
  //     },
  //     {
  //       title: 'expenses',
  //       path: '/dashboard/expenses',
  //       icon: getIcon('mdi:wallet'),
  //       roles: routesAccess.expenses,
  //     },
  //   ],
  // },
];

export default navConfig;
