import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Button, Grid, Stack } from '@mui/material';
// form
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
// i18n
import { useTranslation } from 'react-i18next';
// lodash
import _ from 'lodash';
// components
import FinishedGoodsFields from './FinishedGoodsFields';
import Iconify from '../../../../../components/Iconify';
// default values
import { defaultPackingListDefenitions } from '../defaultValues';

//-------------------------------------------------------------------------------------------------

const FinishedGoodsList = ({ data, finishedGoodInventory, isLoading, editDisabled, defaultValue }) => {
  const { t } = useTranslation();

  // form
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packingListDefenitions',
    defaultValue,
  });

  // fields updates only on add/remove so we useWatch value here
  const fieldsWatch = useWatch({
    control,
    name: `packingListDefenitions`,
    defaultValue,
  });

  const selectedFinishedGoods = _.map(fieldsWatch, 'finishedGoodTemplate');

  const onAdd = () => append(defaultPackingListDefenitions);
  const onRemove = (index) => remove(index);

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item container xs={12} spacing={2}>
        {fields?.map(({ id, _id }, idx) => (
          <FinishedGoodsFields
            key={id}
            // data={_.find(data, { _id })}
            finishedGoodInventory={finishedGoodInventory}
            defaultValue={defaultValue[idx] || null}
            index={idx}
            onRemove={onRemove}
            selectedFinishedGoods={selectedFinishedGoods}
            editDisabled={editDisabled}
            isLoading={isLoading}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Stack alignItems={'flex-end'} direction={'column'}>
          <Button
            disabled={editDisabled}
            variant={'text'}
            onClick={onAdd}
            endIcon={<Iconify icon="eva:plus-fill" width={25} height={25} />}
          >
            {t('purchaseOrderPage.form.button.addFinishedGood')}
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

FinishedGoodsList.propTypes = {
  data: PropTypes.array,
  finishedGoodInventory: PropTypes.array,
  isLoading: PropTypes.bool,
  editDisabled: PropTypes.bool,
  defaultValue: PropTypes.array,
};

export default FinishedGoodsList;
