import { useState, useEffect } from 'react';
// auth
import { useAuth } from '../providers/AuthProvider';

// -----------------------------------------------------------------------

const useAccess = (allowedRoles) => {
  const { account } = useAuth();

  const [hasAccess, setHasAccess] = useState(null);

  const role = account?.role;

  useEffect(() => {
    setHasAccess(() => (allowedRoles ? allowedRoles?.includes(role) : true));
  }, [allowedRoles, role]);

  return hasAccess;
};

export default useAccess;
