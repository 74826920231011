import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// utils
import { get } from 'lodash';
// hooks
import useAccess from '../hooks/useAccess';
import { routesAccess, componentsAccess, UserRoleEnum } from '../services/access';

// ------------------------------------------------------------------

// Core
export const Protected = ({ roles, isRoute, children }) => {
  const hasAccess = useAccess(roles);

  if (hasAccess === null) return null;

  if (hasAccess) return children;

  return isRoute ? <Navigate to="/access-denied" /> : null;
};

Protected.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(UserRoleEnum))),
  isRoute: PropTypes.bool,
  children: PropTypes.node,
};

// Component
export const ProtectedComponent = ({ path, children }) => {
  const roles = get(componentsAccess, path);

  return <Protected roles={roles}>{children}</Protected>;
};

ProtectedComponent.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
};

// Route
export const ProtectedRoute = ({ path, children }) => {
  const roles = get(routesAccess, path);

  return (
    <Protected isRoute roles={roles}>
      {children}
    </Protected>
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
};
