import { createSlice } from '@reduxjs/toolkit';

// utils
import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// reducers
import { CreateUserReducer, GetUsersReducer, UpdateUserReducer } from './reducers';
// thunks
import { CreateUser, GetUsers, UpdateUser } from './thunks';

// ----------------------------------------------------------------------------

// slice
export const inventorySlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: (builder) => {
    // GetUsers
    addExtraReducer(builder, GetUsers, GetUsersReducer);
    // CreateUser
    addExtraReducer(builder, CreateUser, CreateUserReducer);
    // UpdateUser
    addExtraReducer(builder, UpdateUser, UpdateUserReducer);
  },
});

export default inventorySlice.reducer;
