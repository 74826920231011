import { axiosApiInstance } from './axios';

// -------------------------------------------------------------

const apiRoot = '';

const usersApi = {
  // POST
  create: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/users/addUser`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  createByAdmin: async (data) => {
    try {
      const res = await axiosApiInstance.post(`${apiRoot}/users/admin/addUser`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // GET
  get: async () => {
    try {
      const res = await axiosApiInstance.get(`${apiRoot}/users`);

      return res;
    } catch (e) {
      return e.response;
    }
  },

  // UPDATE
  update: async (data, id) => {
    try {
      const res = await axiosApiInstance.put(`${apiRoot}/users/editUser/${id}`, data);

      return res;
    } catch (e) {
      return e.response;
    }
  },
};

export default usersApi;
