import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // const theme = useTheme();

  // const PRIMARY_LIGHT = theme.palette.primary.light;

  // const PRIMARY_MAIN = theme.palette.primary.main;

  // const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 35, height: 35, ...sx }} />;

  const logo = (
    <Box sx={{ width: 35, height: 35, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.67 92.67">
        <defs>
          <linearGradient id="linear-gradient" x1="8.64" y1="-10.2" x2="63.64" y2="72.3" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#0303ad" />
            <stop offset="1" stopColor="#1abaff" />
          </linearGradient>
        </defs>
        <path
          d="M46.33,0A46.34,46.34,0,1,0,92.67,46.33,46.33,46.33,0,0,0,46.33,0ZM60.18,51.81A27.69,27.69,0,0,1,32.49,79.5V40.86A27.69,27.69,0,0,1,60.18,13.17Z"
          fill="url(#linear-gradient)"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/dashboard/app">{logo}</RouterLink>;
}
