import { Navigate } from 'react-router-dom';
// @mui
// import { Typography, Container, Stack } from '@mui/material';
// i18n
// import { useTranslation } from 'react-i18next';

// components
// import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Invoices() {
  // const { t } = useTranslation();

  return <Navigate to={'/in-progress'} />;

  // return (
  //   <Page title={t('pages.invoices')}>
  //     <Container>
  //       <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
  //         <Typography variant="h4" gutterBottom>
  //           {t('pages.invoices')}
  //         </Typography>
  //       </Stack>
  //     </Container>
  //   </Page>
  // );
}
