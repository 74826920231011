import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// lodash
import _ from 'lodash';
// @mui
import {
  Autocomplete,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  createFilterOptions,
} from '@mui/material';
// components
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.object)]),
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onAdd: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
};

export default function RHFAutocomplete({
  name,
  label,
  required,
  placeholder,
  autoFocus,
  options,
  getOptionLabel,
  onAdd,
  loading,
  getOptionDisabled,
  ...other
}) {
  const { control } = useFormContext();

  const withAdd = _.isFunction(onAdd);

  const filter = createFilterOptions();

  const onGetOptionLabel = (option) => {
    const label = getOptionLabel?.(option);

    if (label) return label;

    if (!_.isEmpty(option.inputValue?.trim())) return option.inputValue;

    if (_.isString(option)) return option;

    return '';
  };

  const onFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (!_.isEmpty(params.inputValue?.trim())) {
      const match = filtered.find((el) => {
        const label = onGetOptionLabel(el);
        return el === params.inputValue || label === params.inputValue;
      });

      if (!match)
        filtered.push({
          inputValue: params.inputValue,
          title: params.inputValue,
        });
    }

    return filtered;
  };

  const onChange = (field, newValue) => {
    if (getOptionDisabled?.(newValue)) return;

    if (typeof newValue === 'string') {
      field.onChange(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      field.onChange(newValue.inputValue);
    } else {
      field.onChange(newValue);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          fullWidth
          options={options}
          getOptionLabel={onGetOptionLabel}
          onChange={(_, newValue) => {
            onChange(field, newValue);
          }}
          onBlur={(_, newValue) => field.onBlur(newValue)}
          value={field.value || null}
          {...(withAdd && { filterOptions: onFilterOptions, freeSolo: true, clearOnBlur: true })}
          renderOption={(props, option) => {
            const { inputValue } = option;

            const label = onGetOptionLabel(option);

            const isAddOption = inputValue && _.isFunction(onAdd);

            const _onAdd = async () => {
              const newValue = await onAdd(inputValue);

              onChange(field, newValue || inputValue);
            };

            return (
              <MenuItem
                {...props}
                {...(isAddOption && {
                  onClick: _onAdd,
                  // onKeyDown: (e) => {
                  //   if (e.key === 'Enter') {
                  //     console.log('ENTER');
                  //     _onAdd();
                  //     e.stopPropagation();
                  //   }
                  // },
                })}
              >
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{ sx: { whiteSpace: 'normal', wordWrap: 'break-word' } }}
                />
                {isAddOption && (
                  <ListItemIcon sx={{ minWidth: 'auto !important' }}>
                    <Iconify icon={'carbon:add'} width={25} height={25} />
                  </ListItemIcon>
                )}
              </MenuItem>
            );
          }}
          loading={loading}
          getOptionDisabled={getOptionDisabled}
          {...other}
          renderInput={({ inputProps: { value, ...inputProps }, InputProps, ...params }) => (
            <TextField
              label={label}
              error={!!error}
              helperText={error?.message}
              required={required}
              placeholder={placeholder}
              autoFocus={autoFocus}
              {...params}
              inputProps={{
                ...inputProps,
                value,
                // // value: getOptionLabel(value) || '',
                // onKeyDown: (e) => {
                //   if (e.key === 'Enter') {
                //     console.log(value);
                //     const isNewValue = _.includes(options, value)

                //     // onAdd(params.inputProps.value);
                //     // console.log(options, value, isNewValue);
                //     field.onBlur(value)
                //     // e.stopPropagation();
                //   }
                // },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress size={20} /> : null}
                    {InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}

// AutocompleteOption.propTypes = {
//   field: PropTypes.object,
//   option: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
//   onGetLabel: PropTypes.func,
//   onChange: PropTypes.func,
//   onAdd: PropTypes.func,
// };

// function AutocompleteOption({ field, option, onGetLabel, onChange, onAdd, ...rest }) {
//   const { inputValue } = option;

//   console.log(rest, option);

//   const label = onGetLabel(option);

//   const isAddOption = inputValue && _.isFunction(onAdd);

//   const _onAdd = async () => {
//     const newValue = await onAdd(inputValue);

//     onChange(field, newValue || inputValue);
//   };

//   return (
//     <MenuItem {...rest} {...(isAddOption && { onClick: _onAdd })}>
//       <ListItemText primary={label} primaryTypographyProps={{ sx: { whiteSpace: 'normal', wordWrap: 'break-word' } }} />
//       {isAddOption && (
//         <ListItemIcon sx={{ minWidth: 'auto !important' }}>
//           <Iconify icon={'carbon:add'} width={25} height={25} />
//         </ListItemIcon>
//       )}
//     </MenuItem>
//   );
// }
